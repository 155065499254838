import React, { useCallback, useEffect, useState } from "react";
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Login from "../Pages/Login/Login";
import Home from "../Pages/Home/Home";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import NearByShops from "../Pages/NearByShops/NearByShops";
import StoreRegister from "../Pages/StoreRegister/StoreRegister";
import VerifyDetails from "../Pages/VerifyDetails/VerifyDetails";
import StoreView from "../Pages/StoreView/StoreView";
import Profile from "../Pages/UserProfile/UserProfile";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../Redux/Store/Store";
import SetStore from "../Pages/SetStore/SetStore";
import StoreDashboard from "../Pages/StoreDashboard/StoreDashboard";
import SearchList from "../Pages/SearchList/SearchList";
import Products from "../Pages/Products/Products";

import { setLoginStatus } from "../Redux/loginSlice";
import AddProduct from "../Pages/AddProduct/AddProduct";
import EditProduct from "../Pages/EditProduct/EditProduct";
import ViewProduct from "../Pages/ViewProduct/ViewProduct";
import ProductDetails from "../Pages/ProductDetails/ProductDetails";
import OrderList from "../Pages/OrderList/OrderList";
import ViewOrder from "../Pages/ViewOrder/ViewOrder";
import NearByProducts from "../Pages/NearByProducts/NearByProducts";
import Offers from "../Pages/Offers/Offers";
import CreateOffer from "../Pages/CreateOffer/CreateOffer";
import ViewCart from "../Pages/ViewCart/ViewCart";
import EditOffer from "../Pages/EditOffer/EditOffer";
import ViewOffer from "../Pages/ViewOffer/ViewOffer";
import UserProfile from "../Pages/UserProfile/UserProfile";
import ConfirmOrder from "../Pages/ConfirmOrder/ConfirmOrder";
import OrderSuccessFail from "../Pages/OrderSuccessFail/OrderSuccessFail";
import Payment from "../Pages/Payment/Payment";
import PlaceOrder from "../Pages/PlaceOrder/PlaceOrder";
import AboutUs from "../Pages/AboutUs/AboutUs";
import ContactUs from "../Pages/ContactUs/ContactUs";
import TermsAndConditions from "../Pages/TermsAndConditions/TermsAndConditions";
import Faq from "../Pages/Faq/Faq";
import { STORAGE_CONSTANTS } from "../shared/Constants/Constants";
import ViewPastOrder from "../Pages/ViewPastOrders/ViewPastOrder";
import BankDetails from "../Pages/BankDetails/BankDetails";
import LocationComponent from "../Components/GoogleMaps/LocationComponent";
import ProductReviews from "../Pages/ProductReviews/ProductReviews";
import StoreReviews from "../Pages/StoreReviews/StoreReviews";
import PickupWhereYouLeftFrom from "../Components/PickupWhereYouLeftFrom/PickupWhereYouLeftFrom";
import VerifyAccount from "../Pages/VerifyAccount/VerifyAccount";
import ThankYou from "../Pages/ThankYou/ThankYou";
import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy";
import CancellationPolicy from "../Pages/CancellationPolicy/CancellationPolicy";
import Account from "../Pages/Account/Account";
import ProtectedRoute from "../Components/ProtectedRoute/ProtectedRoute";
import AllNotifications from "../Pages/AllNotifications/AllNotification";

const Index: React.FC = () => {
  // const [auth, setAuth] = useState<boolean>(false);
  const isLoggedIn = useSelector((state: RootState) => state.login.isLoggedIn);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const role = useSelector((state: RootState) => state.User.ActiveRole);

  const showHeaderFooter =
    location.pathname !== "/login" && location.pathname !== "/verify";

  // useEffect(() => {
  //   setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
  // }, []);

  useEffect(() => {
    const isAuthenticated =
      localStorage.getItem(STORAGE_CONSTANTS.IS_LOGGED_IN) === "true";
    dispatch(setLoginStatus(isAuthenticated));
  }, [dispatch]);


  // const beforeunloadHandler = useCallback((e:any) => {
  //     e.preventDefault();
  //   e.returnValue = "";
  //   const token = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  //   if (!token) {
  //     localStorage.clear();
  //   }
  //   sessionStorage.setItem('isReloading', 'true');
  // }, []);

  // const loadHandler = useCallback(() => {
  //   const isReloading = sessionStorage.getItem('isReloading');
  //   if (isReloading === 'true') {
  //     sessionStorage.removeItem('isReloading');
  //     if (window.location.pathname !== "/") {
  //       window.location.replace("/");
  //     }
  //   }
  // }, []);

  // useEffect(() => {
  //   window.addEventListener('beforeunload', beforeunloadHandler);
  //   window.addEventListener('load', loadHandler);

  //   return () => {
  //     window.removeEventListener('beforeunload', beforeunloadHandler);
  //     window.removeEventListener('load', loadHandler);
  //   };
  // }, [beforeunloadHandler, loadHandler]);

  const beforeunloadHandler = useCallback((e: any) => {
    const token = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
    if (!token) {
      e.preventDefault();
      e.returnValue = "";
      sessionStorage.setItem('isReloading', 'true');
    }
  }, []);

  const visibilityChangeHandler = useCallback(() => {
    if (document.visibilityState === 'hidden') {
      const token = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
      if (!token) {
        localStorage.clear();
      }
    }
  }, []);

  const loadHandler = useCallback(() => {
    const isReloading = sessionStorage.getItem('isReloading');
    if (isReloading === 'true') {
      sessionStorage.removeItem('isReloading');
      const currentPath = window.location.pathname;
      const redirectPaths = ["/", "/confirm-order", "/thank-you"];
      if (!redirectPaths.includes(currentPath)) {
        window.location.replace("/");
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', beforeunloadHandler);
    document.addEventListener('visibilitychange', visibilityChangeHandler);
    window.addEventListener('load', loadHandler);

    return () => {
      window.removeEventListener('beforeunload', beforeunloadHandler);
      document.removeEventListener('visibilitychange', visibilityChangeHandler);
      window.removeEventListener('load', loadHandler);
    };
  }, [beforeunloadHandler, visibilityChangeHandler, loadHandler]);


  return (
    <>
      {showHeaderFooter && <Header />}

      <Routes>
        <Route path="/" element={role === "user" ? <Home /> : <StoreDashboard />} />
        <Route
          path="/login"
          element={isLoggedIn ? <Navigate to="/profile" /> : <Login />}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/verify" element={<VerifyDetails />} />
        <Route path="/near-by-shops" element={<NearByShops />} />
        <Route path="/near-by-products" element={<NearByProducts />} />
        <Route path="/store" element={<StoreView />} />
        <Route path="/register" element={<StoreRegister />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/other-information" element={<BankDetails />} />
        <Route path="/set-store" element={<SetStore />} />
        <Route path="/store-dashboard" element={<StoreDashboard />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/search-list" element={<SearchList />} />
        <Route path="/products" element={<Products />} />
        <Route path="/add-product" element={<AddProduct />} />
        <Route path="/edit-product" element={<EditProduct />} />
        <Route path="/view-product" element={<ViewProduct />} />
        <Route path="/product-details" element={<ProductDetails />} />
        <Route path="/orders" element={<OrderList />} />
        <Route path="/view-order" element={<ViewOrder />} />
        <Route path="/offers" element={<Offers />} />
        <Route path="/create-offer" element={<CreateOffer />} />
        <Route path="/edit-offer" element={<EditOffer />} />
        <Route path="/view-offer" element={<ViewOffer />} />
        <Route path="/view-cart" element={<ViewCart />} />
        <Route path="/confirm-order" element={<ConfirmOrder />} />
        <Route path="/thank-you" element={<OrderSuccessFail />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/place-order" element={<PlaceOrder />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/terms-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cancellation-policy" element={<CancellationPolicy />} />
        <Route path="/faqs" element={<Faq />} />
        <Route path="/maps" element={<LocationComponent />} />
        <Route path="/view-past-order" element={<ViewPastOrder />} />
        <Route path="/product-reviews" element={<ProductReviews />} />
        <Route path="/store-reviews" element={<StoreReviews />} />
        <Route path="/verify-store" element={<VerifyAccount />} />
        <Route path="/verification-thank-you" element={<ThankYou />} />
        <Route path="/notifications" element={<AllNotifications />} />

        <Route
          path="/seller-earning"
          element={
            <ProtectedRoute allowedRoles={['store']}>
              <Account />
            </ProtectedRoute>
          }
        />
        {/* <Route path="/seller-earning" element={<Account />} /> */}
      </Routes>

      {showHeaderFooter && <Footer />}
    </>
  );
};

export default Index;
