// ALL Local storages constants
//TODO: value naming conventions needs to be changed
export const STORAGE_CONSTANTS = {
  CURRENT_SELECTED_LANGUAGE: "language_code",
  USER_TOKEN: "user_token",
  ORDER_ID: "order_id",
  SHOP_ID: "shop_id",
  IS_LOGGED_IN: "isLoggedIn",
  LATITUDE: "latitude",
  LONGITUDE: "longitude",
  USER_ID: "user_id",
  USER_OTP: "user_otp",
  USER: "user",
  NUMBER: "number",
  USER_NUMBER: "user_number",
  CONTACT_NUMBER: "contact_number",
  STORE_ID: "storeId",
  STORE_NAME: "store_name",
  STORE_STATUS: "store_status",
  SEARCHED_TEXT: "search",
  CATEGORY_NAME: "cateName",
  UUID: "uuid",
  PRODUCT_ID: "product_Id",
  STORE_LOGO: "storeLogo",
  CHECKOUT_STATUS: "checkout",
  AREA_PIN: "area_pin_code",
  COUPON_CODE: "coupon_code",
  PickupOption: "pickup_code",
};

//Payment Method constants
export const PAYMENT_METHODS_CONSTANTS = {
  ESEWA: "eSewa",
  COD: "cod",
};

export const ORDER_STATUS_MAPPING = {
  PENDING: {
    values: ["PENDING", "new", "NEW"],
    position: 25,
    status: "order_today",
  },
  PROCESSING: {
    values: [
      "Processing",
      "PROCESSING",
      "PARTIALLY_PROCESSING",
      "READY_TO_SHIP",
      "READY TO SHIP",
      "READY_TO_PICK",
      "READY TO PICK",
    ],
    position: 50,
    status: "packing",
  },
  SHIPPING: {
    values: ["OUT_FOR_DELIVERY", "OUT FOR DELIVERY", "Shipped", "SHIPPED"],
    position: 75,
    status: "out_of_delivery",
  },
  DELIVERED: {
    values: ["delivered", "DELIVERED"],
    position: 100,
    status: "arriving",
  },
};

// Order Status or Statuses for Cancel
export const CANCEL_ORDER_STATUS = ["Cancelled"];

export const RET_REF_EXC_ORDER_STATUS = [
  "return",
  "RETURN",
  "refunded",
  "REFUNDED",
  "return_initiate",
  "RETURN_INITIATE",
  "return_refund_apporve",
  "RETURN_REFUND_APPROVE",
  "return_refund_reject",
  "RETURN_REFUND_REJECT",
  "return_refund_processing",
  "RETURN_REFUND_PROCESSING",
  "return_refund_complete",
  "RETURN_REFUND_COMPLETE",
  "exchange_initiate",
  "EXCHANGE_INITIATE",
  "exchange_apporve",
  "EXCHANGE_APPROVE",
  "exchange_processing",
  "EXCHANGE_PROCESSING",
  "exchange_complete",
  "EXCHANGE_COMPLETE",
];

export const IMMUTABLE_ORDER_STATUSES: any = [
  "Complete",
  "COMPLETE",
  "Delivered",
  "DELIVERED",
  "Cancelled",
  "Refunded",
  "REFUNDED",
  "PARTIALLY_CANCELLED",
];
