import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Image, Row } from "react-bootstrap"; // Assuming you're using Bootstrap for UI
import { View_Cart } from "../../Api";
import ProductImage from "../../assets/images/aata.png";
import { base_url } from "../../Api/ApiConst";
import lottieEmptyCart from "../../assets/lotties/emptyCart.json";
import { Player } from "@lottiefiles/react-lottie-player";
import NoDataFound from "../NoDataFound/NoDataFound";

// Define the structure of the product vendor store
interface VendorStore {
    id: number;
    store_name: string;
    city: string;
}

// Define the structure of the current price
interface CurrentPrice {
    discount_type: string;
    discount_val: number;
    current_price: number; // Current price after discount
    current_usd_price: number;
    offer_valid_to: string;
}

// Define the structure of a product
interface Product {
    id: number;
    product_name: string;
    product_description: string;
    product_image: string;
    current_price?: CurrentPrice; // Updated to reflect the nested current_price
}

// Define the structure of the cart details
interface CartDetails {
    order_list: {
        [key: string]: {
            product: Product; // Nested product structure
            price: string; // Store price as a string
            quantity: number;
        }[];
    };
}

const MiniCart: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [products, setProducts] = useState<CartDetails | null>(null);
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [error, setError] = useState<string | null>(null); // For error handling

    const dispatch = useDispatch();

    // Fetch cart details function
    const fetchCartDetails = async () => {
        try {
            setLoading(true);
            const response = await View_Cart();
            if (response && response.status === 200) {
                const cartData = response.data.cart_details.order;
                console.log(cartData, "Fetched Cart Data");

                // Set products based on API response
                setProducts(cartData);

                // Calculate total amount
                const amount = parseFloat(cartData.order_details.sub_total) || 0; // Use sub_total directly
                setTotalAmount(amount);
            } else {
                console.log("Error fetching cart details");
            }
        } catch (error) {
            console.log("Error fetching cart details", error);
            setError("Error fetching cart details");
        } finally {
            setLoading(false);
        }
    };

    // Fetch cart details on component mount
    useEffect(() => {
        fetchCartDetails();
    }, []);

    return (
        <div className="mini-cart-dropdown">
            {loading && !products && (
                <Row>
                    <Col className="d-flex align-items-center justify-content-center">
                        <Player
                            src={lottieEmptyCart}
                            loop
                            autoplay
                            className="player"
                            style={{ height: "100px", width: "100px" }}
                        />
                    </Col>
                </Row>
            )}

            {!loading && (!products || Object.keys(products.order_list).length === 0) ? (
                <div className="text-center">
                    <NoDataFound title="Your cart is empty" />
                </div>
            ) : (
                products && Object.keys(products.order_list).length > 0 && (
                    <div>
                        {/* Top: Subtotal */}
                        <div className="cart-top">
                            <h3>Subtotal: रु{totalAmount.toFixed(2)}</h3>
                        </div>

                        {/* Middle: Cart Items */}
                        <div className="cart-items-wrap">
                            {Object.entries(products.order_list).map(([key, itemList]) =>
                                itemList.map(({ product, price, quantity }) => (
                                    <div key={product.id} className="cart-item">
                                        <div className="product-image-wrap">
                                            <Image
                                                src={`${base_url}/${product.product_image}` || ProductImage}
                                                className="product-image"
                                            />
                                        </div>

                                        <div className="cart-item-details">
                                            <p><strong>{product.product_name}</strong></p>
                                            <p>Quantity: {quantity}</p>
                                            <p>Price: रु{(parseFloat(price) || 0).toFixed(2)}</p>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>

                        {/* Bottom: Cart Summary and View Cart Button */}
                        <div className="cart-summary mt-2">
                            <Button href="/view-cart" className="btnBlue" style={{ padding: 5 }}>
                                View Cart
                            </Button>
                        </div>
                    </div>
                )
            )}
        </div>
    );
};  

export default MiniCart;
