import { CSSProperties } from "react";
import { Right } from "react-bootstrap/lib/Media";

export interface Styles {
  productImage: CSSProperties;
  priceWrap: CSSProperties;
  offerPrice: CSSProperties;
  price: CSSProperties;
  qty: CSSProperties;
  wt: CSSProperties;
  productName: CSSProperties;
  productImageWrap: CSSProperties;
  viewOrder: CSSProperties;
  buyAgain: CSSProperties;
  productRow:CSSProperties;
  completed:CSSProperties;
  completedImage:CSSProperties;
  cancelled:CSSProperties;
  timeDate:CSSProperties;
  title:CSSProperties;
  viewAll:CSSProperties;
  noProduct:CSSProperties;
  btnWrap:CSSProperties;
  btnSort:CSSProperties;
}

const baseStyles: Styles = {
  btnSort: {
    borderWidth: 0,
    backgroundColor: "var(--white-color)",
    float:"right",
    padding:0
  },
  title:{
    fontSize: 24,
    fontFamily: "var(--primary-font-bold)",
    color: "var(--text-color)",
    marginBottom:30
  },
  noProduct:{
    fontSize: 18,
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    fontFamily: "var(--primary-font-semibold)",
    backgroundColor: "var(--primary-color)",
    color:"#fff",
    padding:20,
    borderRadius:16
  },
  viewAll:{
    textAlign:"right",
    fontSize: 16,
    fontFamily: "var(--primary-font-medium)",
    color: "var(--primary-color)"
  },
  productRow: {
    marginBottom: 25,
    paddingBottom: 25,
    borderBottomWidth: 1,
    borderBottomColor: "#D9D9D9",
    borderBottomStyle: "solid",
  },
  productImageWrap: {
    width: 120,
    height: 120,
    borderRadius: 6,
    backgroundColor: "#F5F5F5",
    padding: 10,
  },
  productImage: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
  priceWrap: { marginBottom: 15 },
  price: {
    fontSize: 16,
    fontFamily: "var(--primary-font-medium)",
    color: "#808080",
    textDecoration: "line-through",
    marginLeft: 5,
  },
  offerPrice: {
    fontSize: 24,
    fontFamily: "var(--primary-font-bold)",
    color: "var(--text-color)",
  },
  qty: {
    fontSize: 16,
    fontFamily: "var(--primary-font-medium)",
    color: "#808080",
  },
  wt: {
    fontSize: 16,
    fontFamily: "var(--primary-font-medium)",
    color: "var(--text-color)",
  },
  productName: {
    fontSize: 18,
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--text-color)",
  },
  viewOrder: {
    fontSize: 16,
    fontFamily: "var(--primary-font-medium)",
    color: "var(--text-color)",
    backgroundColor: "var(--white-color)",
    minWidth: 179,
    textAlign: "center",
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 16,
    borderColor: "var(--primary-color)",
    marginRight:20,
  },
  buyAgain: {
    fontSize: 16,
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--white-color)",
    backgroundColor: "var(--primary-color)",
    minWidth: 179,
    textAlign: "center",
    paddingTop: 11,
    paddingBottom: 11,
    borderRadius: 16,
    border: 0,
    marginRight:11
  },
  completed:{
    fontSize: 16,
    fontFamily: "var(--primary-font-semibold)",
    // color: "#4BAE4F",
    color:"red",
    marginRight:12,
    marginBottom:0
  },
  cancelled:{
    fontSize: 16,
    fontFamily: "var(--primary-font-semibold)",
    color: "#808080"
  },
  completedImage:{
    width:32,
    height:32
  },
  timeDate:{
    fontSize: 16,
    fontFamily: "var(--primary-font-medium)",
    color:"var(--text-color)",
    marginBottom:0,
    textAlign:"right"
  },
  btnWrap:{}
};

const mobileStyles: Partial<Styles> = {
  viewOrder: {
    fontSize: 12,
    fontFamily: "var(--primary-font-medium)",
    color: "var(--text-color)",
    backgroundColor: "var(--white-color)",
    width:"90%",
    textAlign: "center",
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 16,
    borderColor: "var(--primary-color)",
    marginBottom:5,
  },
  productImageWrap: {
    width: 100,
    height: 100,
    margin:"auto",
    borderRadius: 6,
    backgroundColor: "#F5F5F5",
    padding: 10,
  },
  btnWrap:{
    paddingLeft:10,
    paddingRight:10
  },
  buyAgain: {
    fontSize: 12,
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--white-color)",
    backgroundColor: "var(--primary-color)",
    width:"90%",
    textAlign: "center",
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 16,
    border: 0,
  },
};
export const getStyles = (isMobile: boolean): Styles => {
  if (isMobile) {
    return {
      ...baseStyles,
      ...mobileStyles,
    };
  }
  return baseStyles;
};

