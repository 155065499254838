import React, { useEffect, useState } from "react";
import { Container, Form, Row, Col, Button, Image, Spinner, Modal } from "react-bootstrap";
import { getStyles } from "./EditProduct.style";
import AddProductImage from "../../assets/images/addProductTitle.png";
import TitleBar from "../../Components/TitleBar/TitleBar";
import Section from "../../Components/Section/Section";
import ProductImage from "../../assets/images/aata.png";
import {
  All_Subcategories,
  Edit_Product,
  Product_Delete,
  Product_Details,
  Unit_Of_Measure,
} from "../../Api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { base_url } from "../../Api/ApiConst";
import { FaArrowLeft } from "react-icons/fa";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/Store";

interface Category {
  id: string;
  name: string;
  // store_name: string;
  // email: string;
  // contact_number: any
}

const EditProduct: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [isOutOfStock, setIsOutOfStock] = useState(false);
  const [loading, setLoading] = useState(false);


  const styles = getStyles(isMobile);
  const breadcrumbs = [
    <Link to="/" key="home">
      {t("footer.home")}
    </Link>,
    "Store",
  ];
  const title: string = t("product.editProduct");
  const imageUrl: string = AddProductImage;

  const [productType, setProductType] = useState<"single" | "bulk" | "system">(
    "single"
  );
  // const [productName, setProductName] = useState("");
  // const [category, setCategory] = useState("");
  // const [description, setDescription] = useState("");
  const [unitsOfMeasure, setUnitsOfMeasure] = useState<any>("");
  // const [variants, setVariants] = useState("");
  // const [minQuantity, setMinQuantity] = useState("");
  // const [inventory, setInventory] = useState("");
  // const [price, setPrice] = useState("");
  const [product_id, setProduct_Id] = useState<string | null>("");
  const [productImage, setProductImage] = useState<any>("");
  const [additionalImage1, setAdditionalImage1] = useState<any>("");
  const [additionalImage2, setAdditionalImage2] = useState<any>("");
  const [productImages, setProductImages] = useState<any>("");
  const [additionalImages1, setAdditionalImages1] = useState<any>("");
  const [additionalImages2, setAdditionalImages2] = useState<any>("");
  const [selectedImage, setSelectedImage] = useState<any>("");
  const [selectedAdditionalImage1, setSelectedAdditionalImage1] =
    useState<any>("");
  const [selectedAdditionalImage2, setSelectedAdditionalImage2] =
    useState<any>("");
  const [userToken, setUserToken] = useState<string | null>("");
  const [sub, setSub] = useState<Category[]>([]);
  // const [prevData, setPrevData] = useState<any>("");
  const [storeError, setStoreError] = useState<{
    product_image?: string;
    additional_image_1?: string;
    additional_image_2?: string;
    maximum_qty?: string;
  }>({});
  const userPincode = useSelector((state: RootState) => state.Location.pincode);
  const [showDeleteModal, setShowDeleteModal] = useState(false); // state for controlling the modal visibility

  const userLatitude = useSelector(
    (state: RootState) => state.Location.selected_latitude
  );
  const userLongitude = useSelector(
    (state: RootState) => state.Location.selected_longitude
  );

  // const [productQuantity, setProductQuantity] = useState("");
  const [systemProductID, setSystemProductID] = useState("");
  // const [productNameInNepali, setProductNameInNepali] = useState<any>("");
  // const [descriptionInNepali, setDescriptionInNepali] = useState("");
  // const [priceInNepali, setPriceInNepali] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setProduct_Id(localStorage.getItem(STORAGE_CONSTANTS.PRODUCT_ID));
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    fetchCategories();
    fetchUnit();
    window.scrollTo(0, 0);
  }, []);

  const schema = yup.object().shape({
    productName: yup
      .string()
      .required(t("responseMessage.productNameRequired")),
    unitOfMeasure: yup.string().required(t("responseMessage.selectAnOption")),
    category: yup.string().required(t("responseMessage.selectAnOption")),
    variants: yup
      .string()
      .required(t("responseMessage.variantsRequired"))
      .matches(/^[0-9]+(\.[0-9]+)?$/, "Invalid variants"),
    maxQuantity: yup.string(),
    // .matches(/^[0-9]+$/, "* Invalid variants"),
    productNameInNepali: yup
      .string()
      .required(t("responseMessage.neNameRequired")),
    descriptionInNepali: yup
      .string()
      .required(t("responseMessage.neDescriptionRequired")),
    priceInNepali: yup
      .string()
      .required(t("responseMessage.priceRequired"))
      .matches(/^[0-9]+(\.[0-9]+)?$/, t("responseMessage.invalidPrice")),

    description: yup
      .string()
      .required(t("responseMessage.descriptionRequired")),
    // .matches(/^[a-zA-Z0-9\s!@#$%^&*()\-]+$/,t("responseMessage.addValidDescription")),

    minQuantity: yup
      .string()
      .required(t("responseMessage.qtyRequired"))
      .matches(/^[0-9]+(\.[0-9]+)?$/, t("responseMessage.invalidQuantity")) 
      .test("minQuantity", t("responseMessage.minQuantityGreaterThanZero"), (value) => {
        return parseFloat(value) > 0;
      }),

    // price: yup
    //   .string()
    //   .required(t("responseMessage.priceRequired"))
    //   .matches(/^[0-9]+(\.[0-9]+)?$/, "* Invalid Price"),

    inventory: yup
      .string()
      .required("* Inventory is required")
      .matches(/^[0-9]+$/, t("responseMessage.invalidPrice")),

    sku: yup.string().required("* Sku is required"),

    threshold_limit: yup
      .string()
      .required("* Threshold limit is required")
      .matches(/^[0-9]+(\.[0-9]+)?$/, "* Invalid limit"),

    brand: yup.string().required("* Brand name is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const fetchCategories = async () => {
    try {
      const response = await All_Subcategories();
      if (response && response.status === 200) {
        // console.log(response.data, "sub------------->");
        setSub(response.data);
      } else {
        console.log("Error fetching Subcategories");
      }
    } catch (error) {
      console.log("Error fetching Subcategories", error);
    }
  };

  const fetchUnit = async () => {
    try {
      const response = await Unit_Of_Measure();
      if (response && response.status === 200) {
        // console.log(response.data, "response==============>");
        // setSub(response.data);
        setUnitsOfMeasure(response.data.product_list);
      } else {
        console.log("Error fetching Subcategories");
      }
    } catch (error) {
      console.log("Error fetching Subcategories", error);
    }
  };

  const fetchStoreList = async () => {
    try {
      const response = await Product_Details(
        product_id,
        userLatitude,
        userLongitude,
        userPincode
      );
      if (response && response.status === 200) {
        let data = response?.data?.product_details;
        console.log(data, "details==========>");
        setProductImages(data?.product_image);
        setAdditionalImages1(data?.additional_image_1);
        setAdditionalImages2(data?.additional_image_2);
        reset({
          productName: data?.product_name ?? "",
          description: data?.product_description ?? "",
          unitOfMeasure: data?.unit_measure ?? "",
          minQuantity: data?.minimum_qty ?? "",
          maxQuantity: data.maximum_qty ?? "",
          // price: data?.USDPrice ?? "",
          inventory: data?.available ?? "",
          category: data?.product_subcategory.id ?? "",
          variants: data?.PRDWeight.toString() ?? "",
          productNameInNepali:
            data?.language_data.length > 0
              ? data?.language_data[0].product_name
              : "",
          descriptionInNepali:
            data?.language_data.length > 0
              ? data?.language_data[0].product_description
              : "",
          priceInNepali: data?.PRDPrice ?? "",
          sku: data?.sku ?? "",
          brand: data?.brand ?? "",
          threshold_limit: data?.threshold_limit ?? "",


        });

      } else {
        console.log("Error fetching Store Product Details");
      }
    } catch (error) {
      console.log("Error fetching Store Product Details", error);
    }
  };

  useEffect(() => {
    if (product_id) {
      fetchStoreList();
    }
  }, [product_id]);

  const handleProductTypeChange = (type: "single" | "bulk" | "system") => {
    setProductType(type);
    // Reset related fields when switching product types
    // setProductName("");
    // setCategory("");
    // setDescription("");
    // setUnitOfMeasure("");
    // setVariants("");
    // setMinQuantity("");
    // setInventory("");
    // setPrice("");
    // setProductQuantity("");
    // setSystemProductID("");
  };
  const handleOutOfStockChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsOutOfStock(e.target.checked); // Update checkbox state
  };
  const handleSubmits = (data: any) => {
    // Implement your logic to handle form submission
    setLoading(true);

    const updatedData = { ...data, inventory: isOutOfStock ? 0 : data.inventory };

    if (updatedData) {
      const EditProduct = async () => {
        try {
          const response = await Edit_Product(
            userToken,
            updatedData,
            productImage,
            product_id,
            additionalImage1,
            additionalImage2
          );
          if (response && response.status === 200) {
            // console.log(response.data.bought_together[0]);
            toast.success(response["data"]["message"]);
            navigate("/products");
          } else {
            console.log("Error Editing Store Product Details", response.data);
          }
        } catch (error: any) {
          console.log(
            "Error Editing Store Product Details",
            error.response.data
          );
          setStoreError(error?.response?.data?.error);
        } finally {
          // Step 3: Reset loading state after submission
          setLoading(false);
        }
      };
      EditProduct();
    }
  };

  // console.log(category)
  // console.log(price)
  const handleDelete = async () => {

    try {
      const response = await Product_Delete(product_id, userToken);
      if (response && response.status === 200) {
        // console.log(response.data)
        toast.success(response["data"]["message"]);
        setShowDeleteModal(false); // Close modal after deletion
        navigate("/products");
      } else {
        console.log("Error Deleting the product");
      }
    } catch (error) {
      console.log("Error Deleting the product", error);
    }
  };
  const handleShowDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };



  const handleFileChange = (e: any) => {
    // console.log("product image", e);
    // setProductImage(e.target.files[0]);
    const file = e.target.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      setProductImage(file);
    }
  };

  const handleCancel = () => {
    navigate("/products");
  };

  const handleAdditionalFile1 = (e: any) => {
    // console.log("product image1", e);
    // setAdditionalImage1(e.target.files[0]);
    const file = e.target.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      // console.log(file,"imageUrl==========>")
      setSelectedAdditionalImage1(imageUrl);
      setAdditionalImage1(file);
    }
  };

  const handleAdditionalFile2 = (e: any) => {
    // console.log("product image1", e);
    // setAdditionalImage2(e.target.files[0]);
    const file = e.target.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedAdditionalImage2(imageUrl);
      setAdditionalImage2(file);
    }
  };

  // console.log(selectedAdditionalImage1, "hiiiiiii=>");

  return (
    <div className="editProduct">
      <TitleBar breadcrumbs={breadcrumbs} title={title} imageUrl={imageUrl} />
      <Section className="addProduct" style={{ marginTop: -60 }}>
        <div style={styles.whiteWrap}>
          <Container>
            <Form onSubmit={handleSubmit(handleSubmits)}>
              {/* <div style={{ display: "flex", justifyContent: "center",position:"relative" }}>
              <div style={{position:"absolute",left:0,top:5}} className="hide-xs">
              <Link to="/products"><FaArrowLeft className="arrow-left"/> </Link>
            </div>
            <div style={{position:"relative",left:0,top:0}} className="hide-lg"> 
              <Link to="/products"><FaArrowLeft className="arrow-left"/> </Link>
            </div>
                <Form.Group>
                  <Form.Check
                    inline
                    type="radio"
                    id="single"
                    label={t("product.single")}
                    checked={productType === "single"}
                    onChange={() => handleProductTypeChange("single")}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    id="bulk"
                    label={t("product.bulk")}
                    checked={productType === "bulk"}
                    onChange={() => handleProductTypeChange("bulk")}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    id="system"
                    label={t("product.fromSystem")}
                    checked={productType === "system"}
                    onChange={() => handleProductTypeChange("system")}
                  />
                </Form.Group>
              </div> */}

              {productType === "single" && (
                <>
                  <div style={styles.checkRow}>
                    <label style={styles.checkLabel}>
                      <input
                        type="checkbox"
                        checked={isOutOfStock}
                        style={styles.checkbox}
                        onChange={handleOutOfStockChange}
                      />
                      {t("Mark as Out of Stock")}
                    </label>
                  </div>
                  <Form.Group controlId="productName">
                    <Form.Label>{t("product.productName")}*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("product.enterProductName")}
                      // value={productName}
                      {...register("productName")}
                      isInvalid={!!errors.productName}
                    />
                    {errors?.productName && (
                      <span style={styles.error}>
                        {errors?.productName?.message}
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group controlId="category">
                    <Form.Label>{t("product.category")}*</Form.Label>
                    <Controller
                      name="category"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Form.Control as="select" {...field}>
                          <option value="" disabled>
                            {t("product.selectSubcategory")}
                          </option>
                          {sub &&
                            sub.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                        </Form.Control>
                      )}
                    />
                    {errors?.category && (
                      <span style={styles.error}>
                        {errors?.category?.message}
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group controlId="description">
                    <Form.Label>{t("product.description")}*</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder={t("product.enterProductDescription")}
                      {...register("description")}
                    // value={description}
                    // onChange={(e) => setDescription(e.target.value)}
                    />
                    {errors?.description && (
                      <span style={styles.error}>
                        {errors?.description?.message}
                      </span>
                    )}
                  </Form.Group>
                  <Row>
                    <Form.Group as={Col} controlId="variants">
                      <Form.Label>{t("product.variations")}*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("product.enterProductVariants")}
                        {...register("variants")}

                      // value={inventory}
                      // onChange={(e) => setInventory(e.target.value)}
                      />
                      {errors?.variants && (
                        <span style={styles.error}>
                          {errors?.variants?.message}
                        </span>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} controlId="unitOfMeasure">
                      <Form.Label>{t("product.unitOfMeasure")}*</Form.Label>
                      <Controller
                        name="unitOfMeasure"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Form.Control as="select" {...field}>
                            <option value="" disabled>
                              {"Select an option"}
                            </option>
                            {unitsOfMeasure &&
                              unitsOfMeasure.map((item: any) => (
                                <option
                                  key={item.id}
                                  value={item.unit_of_measure}
                                >
                                  {item.unit_of_measure}
                                </option>
                              ))}
                          </Form.Control>
                        )}
                      />
                      {errors?.unitOfMeasure && (
                        <span style={styles.error}>
                          {errors?.unitOfMeasure?.message}
                        </span>
                      )}
                    </Form.Group>

                    {/* <Form.Group as={Col} controlId="price">
                      <Form.Label>{t("product.priceUsd")}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("product.enterProductPrice")}
                        {...register("price")}

                        // value={price}
                        // onChange={(e) => setPrice(e.target.value)}
                      />
                      {errors?.price && (
                        <span style={styles.error}>
                          {errors?.price?.message}
                        </span>
                      )}
                    </Form.Group> */}
                  </Row>
                  <Row>
                    <Form.Group as={Col} controlId="minQuantity">
                      <Form.Label>{t("product.minimumQuantity")}*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("product.enterMinimumQuantity")}
                        {...register("minQuantity")}

                      // value={minQuantity}
                      // onChange={(e) => setMinQuantity(e.target.value)}
                      // required
                      />
                      {errors?.minQuantity && (
                        <span style={styles.error}>
                          {errors?.minQuantity?.message}
                        </span>
                      )}
                    </Form.Group>

                    <Form.Group as={Col} controlId="maxQuantity">
                      <Form.Label>{t("product.maximumQuantity")}*</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder={t("product.enterMaximumQuantity")}
                        {...register("maxQuantity")}

                      // value={variants}
                      // onChange={(e) => setVariants(e.target.value)}
                      />
                      {storeError?.maximum_qty && (
                        <span style={styles.error}>
                          {storeError?.maximum_qty}
                        </span>
                      )}
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group as={Col} controlId="Brand">
                      <Form.Label>{t("product.brand")}*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={"Enter brand name"}
                        {...register("brand")}

                      // value={minQuantity}
                      // onChange={(e) => setMinQuantity(e.target.value)}
                      // required
                      />
                      {errors?.brand && (
                        <span style={styles.error}>
                          {errors?.brand?.message}
                        </span>
                      )}
                      {/* {storeError?.PRDWeight && (
                        <span style={styles.error}>
                          {storeError?.PRDWeight}
                        </span>
                      )} */}
                    </Form.Group>

                    <Form.Group as={Col} controlId="Sku">
                      <Form.Label>{t("product.sku")}*</Form.Label>
                      <Form.Control
                        type="text"
                        // placeholder={"Enter maximum quantity"}
                        {...register("sku")}

                      // value={variants}
                      // onChange={(e) => setVariants(e.target.value)}
                      />
                      {errors?.sku && (
                        <span style={styles.error}>{errors?.sku?.message}</span>
                      )}
                      {/* {storeError?.maximum_qty && (
                        <span style={styles.error}>
                          {storeError?.maximum_qty}
                        </span>
                      )} */}
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} controlId="inventory">
                      <Form.Label>{t("product.inventory")}*</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder={t("product.inventory")}
                        {...register("inventory")}

                      // value={inventory}
                      // onChange={(e) => setInventory(e.target.value)}
                      />
                      {errors?.inventory && (
                        <span style={styles.error}>
                          {errors?.inventory?.message}
                        </span>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} controlId="threshold_limit">
                      <Form.Label>{"Threshold limit"}*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={"Enter threshold limit"}
                        {...register("threshold_limit")}

                      // value={priceInNepali}
                      // onChange={(e) => setPriceInNepali(e.target.value)}
                      // required
                      />
                      {errors?.priceInNepali && (
                        <span style={styles.error}>
                          {errors?.threshold_limit?.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                  <Form.Group as={Col} controlId="priceInNepali">
                    <Form.Label>{t("product.priceInNepali")}*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("product.priceInNepali")}
                      {...register("priceInNepali")}

                    // value={priceInNepali}
                    // onChange={(e) => setPriceInNepali(e.target.value)}
                    // required
                    />
                    {errors?.priceInNepali && (
                      <span style={styles.error}>
                        {errors?.priceInNepali?.message}
                      </span>
                    )}
                  </Form.Group>
                  <Row className="align-items-center sm-edit-image-row xs-row-wrap">
                    {(productImages || selectedImage) && (
                      <Col sm={1}>
                        <div style={{ ...styles.imageWrap, margin: "auto" }}>
                          <Image
                            src={
                              selectedImage
                                ? selectedImage
                                : `${base_url}${productImages}`
                            }
                            style={styles.image}
                          />
                        </div>
                      </Col>
                    )}
                    <Col sm={productImages ? 11 : 12}>
                      <Form.Group controlId="productImage">
                        <Form.Label>{t("product.productImage")}</Form.Label>
                        <Form.Control
                          type="file"
                           accept="image/*"
                          onChange={(e) => handleFileChange(e)}
                        />
                        {storeError?.product_image && (
                          <span style={styles.error}>
                            {storeError?.product_image}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="align-items-center sm-edit-image-row xs-row-wrap">
                    {(additionalImages1 || selectedAdditionalImage1) && (
                      <Col sm={1}>
                        <div style={{ ...styles.imageWrap, margin: "auto" }}>
                          <Image
                            src={
                              selectedAdditionalImage1
                                ? selectedAdditionalImage1
                                : `${base_url}${additionalImages1}`
                            }
                            style={styles.image}
                          />
                        </div>
                      </Col>
                    )}
                    <Col sm={additionalImages1 ? 11 : 12}>
                      <Form.Group controlId="AdditionalImage1">
                        <Form.Label>{t("product.additionalImage1")}</Form.Label>
                        <Form.Control
                          type="file"
                           accept="image/*"
                          onChange={(e) => handleAdditionalFile1(e)}
                        />
                        {/* {storeError.Additional_image && (
                      <span style={styles.error}>
                        {storeError.Additional_image}
                      </span>

                    )} */}
                        {storeError.additional_image_1 && (
                          <span style={styles.error}>
                            {storeError?.additional_image_1}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="align-items-center sm-edit-image-row xs-row-wrap">
                    {(additionalImages2 || selectedAdditionalImage2) && (
                      <Col sm={1}>
                        <div style={{ ...styles.imageWrap, margin: "auto" }}>
                          <Image
                            src={
                              selectedAdditionalImage2
                                ? selectedAdditionalImage2
                                : `${base_url}${additionalImages2}`
                            }
                            style={styles.image}
                          />
                        </div>
                      </Col>
                    )}
                    <Col sm={additionalImages2 ? 11 : 12}>
                      <Form.Group controlId="AdditionalImage2">
                        <Form.Label>{t("product.additionalImage2")}</Form.Label>
                        <Form.Control
                          type="file"
                           accept="image/*"
                          onChange={(e) => handleAdditionalFile2(e)}
                        />
                        {storeError.additional_image_2 && (
                          <span style={styles.error}>
                            {storeError?.additional_image_2}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>


                  <p style={styles.heading}>{t("product.otherLanguage")}</p>
                  <Form.Group as={Col} controlId="productNameInNepali">
                    <Form.Label>{t("product.productNameInNepali")}*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("product.productNameInNepali")}
                      {...register("productNameInNepali")}

                    // value={productNameInNepali}
                    // onChange={(e) => setProductNameInNepali(e.target.value)}
                    // required
                    />
                    {errors?.productNameInNepali && (
                      <span style={styles.error}>
                        {errors?.productNameInNepali?.message}
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group as={Col} controlId="descriptionInNepali">
                    <Form.Label>{t("product.descriptionInNepali")}*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("product.descriptionInNepali")}
                      {...register("descriptionInNepali")}

                    // value={descriptionInNepali}
                    // onChange={(e) => setDescriptionInNepali(e.target.value)}
                    // required
                    />
                    {errors?.descriptionInNepali && (
                      <span style={styles.error}>
                        {errors?.descriptionInNepali?.message}
                      </span>
                    )}
                  </Form.Group>
                </>
              )}

              {productType === "bulk" && <></>}

              {productType === "system" && (
                <Form.Group controlId="systemProductID">
                  <Form.Label>System Product ID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter system product ID"
                    value={systemProductID}
                    onChange={(e) => setSystemProductID(e.target.value)}
                    required
                  />
                </Form.Group>
              )}
              <Row>
                <Col xs={12}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      variant="primary"
                      type="submit"
                      style={styles.btnRed}
                      onClick={handleShowDeleteModal}
                    >
                      {t("commonHeader.delete")}
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      style={styles.btnBlue}
                      disabled={loading} // Disable the button when loading
                    >
                      {loading ? (
                        <Spinner animation="border" size="sm" /> // Render spinner when loading
                      ) : (
                        t("commonHeader.save")
                      )}
                    </Button>
                    <Button
                      variant="primary"
                      style={styles.btnCancel}
                      onClick={handleCancel}
                    >
                      {t("commonHeader.cancel")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
      </Section>
      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} className="logoutModal" onHide={handleCloseDeleteModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("commonHeader.deleteConfirmation")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("commonHeader.areYouSureDelete")}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            {t("commonHeader.cancel")}
          </Button>
          <Button
            variant="danger"
            onClick={handleDelete}
          >
            {t("commonHeader.delete")}

          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditProduct;
