import { toast } from "react-toastify";
import { Add_To_Wishlist, Delete_Wishlist, View_Wishlist } from "../Api";
import store from "../Redux/Store/Store";
import { SET_PRODUCT_ID_IN_WISHLIST } from "../Redux/User";
import { STORAGE_CONSTANTS } from "../shared/Constants/Constants";


export const fetchWishlist = async () => {
  const userToken = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  try {
    if (userToken) {
      const response = await View_Wishlist(userToken);
      if (response && response.status === 200) {
        // console.log("llist length ", response.data.wishlist_details.product_list.length)
        const wishlistProductIds =
          response.data.wishlist_details.product_list.map(
            (item: any) => item.id
          );
        store.dispatch(SET_PRODUCT_ID_IN_WISHLIST(wishlistProductIds));
      }
    }
  } catch (error) {
    console.log("Error fetching wishlist:", error);
  }
};


export const AddToWishList = async (id: any) => {
  const userToken = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  try {
    const response = await Add_To_Wishlist(userToken, id);
    if (response && response.status === 200) {
      toast.success(response["data"]["message"]);
      fetchWishlist();
    } else {
      toast.error(response["data"]["message"]);
    }
  } catch (error: any) {
    console.log("Error occurred:", error.response.data.error_message);
  }
};

export const handleRemoveFromWishlist = async (id: any) => {
  const userToken = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  try {
    const response = await Delete_Wishlist(userToken, id);
    if (response && response.status === 200) {
      fetchWishlist();
      toast.success("Product removed from wishlist successfully");
    } else {
      toast.error(
        response?.data?.message || "Failed to remove product from wishlist"
      );
    }
  } catch (error) {
    console.error(
      "Error occurred while removing product from wishlist:",
      error
    );
    toast.error("Failed to remove product from wishlist");
  }
};
