import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../Redux/Store/Store';
import UnauthorizedModal from '../UnauthorizedModal/UnauthorizedModal';

interface ProtectedRouteProps {
  children: React.ReactNode;
  allowedRoles?: string[]; // Optional roles that can access the route
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, allowedRoles }) => {
  const isLoggedIn = useSelector((state: RootState) => state.login.isLoggedIn);
  const userRole = useSelector((state: RootState) => state.User.ActiveRole);
  const [showModal, setShowModal] = useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (allowedRoles && !allowedRoles.includes(userRole)) {
      setShowModal(true);
    }
  }, [allowedRoles, userRole]);

  const handleCloseModal = () => {
    setShowModal(false);
    setRedirect(true); // Trigger redirection after modal closes
  };

  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  if (redirect) {
    return <Navigate to="/" replace />;
  }

  if (allowedRoles && !allowedRoles.includes(userRole)) {
    return (
      <>
        <UnauthorizedModal show={showModal} handleClose={handleCloseModal} />
      </>
    );
  }

  return <>{children}</>;
};

export default ProtectedRoute;
