import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom"; // Assuming you're using React Router
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  Image,
  Form,
  Spinner,
  Tabs,
  Tab,
} from "react-bootstrap"; // Assuming you're using Bootstrap for styling
import shopImage from "../../assets/images/shop-icon.png";
import timerImage from "../../assets/images/timer.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartOutline } from "@fortawesome/free-regular-svg-icons";
// import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { getStyles } from "./ProductDetails.style";
import Section from "../../Components/Section/Section";
import defaultAvatar from "../../assets/images/User.png";
import {
  Add_To_Cart,
  Add_To_Wishlist,
  Product_Details,
  Product_Details_Rating,
  Product_Ratings,
  Remove_From_Cart,
  Update_Cart,
  View_Cart,
} from "../../Api";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/Store";
import { base_url } from "../../Api/ApiConst";
import { VIEW_CART } from "../../Redux/Cart";
import { toast } from "react-toastify";
import {
  AddToWishList,
  fetchWishlist,
  handleRemoveFromWishlist,
} from "../../Utils/wishListUtils";

import PeopleAlsoBought from "../../Components/PeopleAlsoBought/PeopleAlsoBought";
import { getQuantity, handleShowInventoryLabel } from "../../Utils/cartUtils";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import Carousel from "react-multi-carousel";
import {
  faHeart,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { SET_SELECTED_STORE_SUBCATEGORY } from "../../Redux/User";
import { Rating } from "react-simple-star-rating";
import NoDataFound from "../../Components/NoDataFound/NoDataFound";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  largeDesktop: {
    breakpoint: { max: 3000, min: 1901 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 1900, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const anonymousUser = "Anonymous User";

// const reviews = [
//   {
//     profileImage: "https://via.placeholder.com/50",
//     name: "John Doe",
//     rating: 4,
//     comment: "Great product! Highly recommend.",
//   },
//   {
//     profileImage: "",
//     name: "",
//     rating: 5,
//     comment: "Amazing quality and fast shipping.",
//   },
//   {
//     profileImage: "https://via.placeholder.com/50",
//     name: "Jane Smith",
//     rating: 3,
//     comment: "Good product, but had some issues with the packaging.",
//   },
//   {
//     profileImage: "",
//     name: "Michael Johnson",
//     rating: 2,
//     comment: "Not as expected. The quality could be better.",
//   },
// ];

const ProductDetails: React.FC = () => {
  interface Category {
    id: string;
    product_name: string;
    product_image: string;
    additional_image_1: string;
    additional_image_2: string;
    PRDPrice: number;
    PRDWeight: string;
    product_vendor_store?: {
      store_name: string;
    };
    language_data?: {
      product_name: string;
      product_description: string;
    };
  }
  const { t, i18n } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = getStyles(isMobile);
  const [selectedOption, setSelectedOption] = useState<string>("delivery");
  const [product, setProduct] = useState<any>({});
  const [products, setProducts] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [state, setState] = useState<boolean>();
  const [userToken, setUserToken] = React.useState<string | null>("");
  const [quantity, setQuantity] = useState<number>(1);
  const [currency, setCurrency] = useState("NPR");
  const [reviews, setReviews] = useState<any>("");

  const product_id = useSelector(
    (state: RootState) => state.productId.selected_product_id
  );
  const wishlist: any = useSelector(
    (state: RootState) => state.User.productIdInWishList
  );
  const [orderId, setOrderId] = useState<any>("");
  const userLatitude = useSelector(
    (state: RootState) => state.Location.selected_latitude
  );
  const userLongitude = useSelector(
    (state: RootState) => state.Location.selected_longitude
  );
  const languageId: any = useSelector((state: RootState) => state.lan);
  const cartOrderId = useSelector((state: RootState) => state.Cart.View_Cart);
  const [prodQuantity, setProdQuantity] = useState<number>(1);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };
  const [expanded, setExpanded] = useState(false);

  const outOfstock = useSelector((state: RootState) => state.offer.OutOfStock);
  const userPincode = useSelector((state: RootState) => state.Location.pincode);
 
  useEffect(() => {
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    Cart_Details();
    window.scrollTo(0, 0);
  }, []);

  const update = () => {
    // Object.keys(products).map((item:any) => {console.log(item,"item------>")})
    console.log("hello");
  };

  const fetchStoreList = async () => {
    try {
      setLoading(true);
      const response = await Product_Details(
        product_id,
        userLatitude,
        userLongitude,
        userPincode
      );
      if (response && response.status === 200) {
        // updateQuantity()
        console.log(response.data, "jjjjjjjjjjjjjjjjjjj------");
        // let data = response.data.bought_together[0];
        setProduct(response.data);
        setProdQuantity(
          getQuantity(
            response.data?.product_details?.product_vendor_store?.id,
            response.data?.product_details?.id
          )
        );
      } else {
        console.log("Error fetching Store Product Details");
      }
    } catch (error) {
      console.log("Error fetching Store Product Details", error);
    } finally {
      setLoading(false);
    }
  };

  // const incrementQuantity = () => {
  //   setQuantity(quantity + 1);
  // };

  // const decrementQuantity = () => {
  //   if (quantity > 1) {
  //     setQuantity(quantity - 1);
  //   }
  // };

  useEffect(() => {
    if (product_id) {
      fetchStoreList();
      update();
      handleReviews();
    }
  }, [product_id]);

  // useEffect(() => {
  //   // Scroll to the top when the component mounts
  //   // updateQuantity()
  //   Cart_Details();
  //   window.scrollTo(0, 0);
  // }, []);

  const handleCart = (id: any,minimum_qty:any) => {
    const addToCart = async () => {
      try {
        const response = await Add_To_Cart(id, currency, minimum_qty);
        if (response && response.status === 200) {
          if (response.data.status === false) {
            toast.error("Product is Out of stock!");
          } else {
            localStorage.setItem(
              STORAGE_CONSTANTS.ORDER_ID,
              response?.data?.order_id
            );
            toast.success(response["data"]["message"]);
            const Cartresponse = await View_Cart();
            if (Cartresponse && Cartresponse.status === 200) {
              console.log(Cartresponse.data, "kkkkkkkkkkkk");
              setOrderId(Cartresponse?.data?.cart_details?.order.id);
              dispatch(VIEW_CART(Cartresponse.data));
              setProdQuantity(
                getQuantity(
                  product?.product_details?.product_vendor_store?.id,
                  product?.product_details?.id
                )
              );
            }
          }
        } else {
          toast.error(response["data"]["message"]);
        }
      } catch (error: any) {
        console.log("Error occurred:", error.response.data.error_message);
      }
    };
    addToCart();
  };

  const Cart_Details = async () => {
    try {
      const response = await View_Cart();
      if (response && response.status === 200) {
        console.log(response.data.cart_details, "hgggggggggggggggggggggggj");
        setProducts(response.data.cart_details.order.order_list);
        setOrderId(response.data.cart_details.order.id);

        // setStores(response.data.store_list);
      } else {
        console.log("Error fetching Cart details");
      }
    } catch (error: any) {
      console.log("Error fetching Cart details", error);
    }
  };

  const handleReviews = async () => {
    try {
      const response = await Product_Details_Rating(product_id);
      if (response && response.status === 200) {
        console.log(response.data, "Reviews=========>");
        if(response.data.status === true){
          setReviews(response.data.product_rating_list);
        }else{
          setReviews("")
        }
        // setStores(response.data.store_list);
      } else {
        console.log("Error fetching Cart details");
      }
    } catch (error: any) {
      console.log("Error fetching Cart details", error);
    }
  };

  const incrementQuantity = (id: any) => {
    setProdQuantity((prev: any) => prev + 1);
    const Update_product = async () => {
      try {
        const response = await Update_Cart(
          currency,
          prodQuantity + 1,
          id,
          orderId
        );
        if (response && response.status === 200) {
          console.log(response.data, "------------------>");
          const Cartresponse = await View_Cart();
          if (Cartresponse && Cartresponse.status === 200) {
            console.log(Cartresponse?.data,"cart------->")
            dispatch(VIEW_CART(Cartresponse.data));
          }
          setState(true);
          Cart_Details();
          // toast.success(response["data"]["message"]);
           if (response?.data?.error) {
                      toast.error(response?.data?.error);
                      // setLimit(response?.data?.error);
                    } else {
                      toast.success(response["data"]["message"]);
                    }
          // setStores(response.data.store_list);
        } else {
          console.log("Error fetching Cart details");
        }
      } catch (error: any) {
        console.log("Error fetching Cart details", error);
        toast.error(error.response.data.message);
      }
    };
    Update_product();
  };

  const decrementQuantity = async (id: any,minimum_qty:any) => {
    setProdQuantity((prev: any) => prev - 1);
    if (prodQuantity > minimum_qty) {
      // If quantity is greater than 1, decrement quantity
      const Update_product = async () => {
        try {
          const response = await Update_Cart(
            currency,
            prodQuantity - 1,
            id,
            orderId
          );
          if (response && response.status === 200) {
            console.log(response.data, "------------------>");
            const Cartresponse = await View_Cart();
            if (Cartresponse && Cartresponse.status === 200) {
              dispatch(VIEW_CART(Cartresponse.data));
            }
            setState(true);
            Cart_Details();
            toast.success(response["data"]["message"]);
            // setStores(response.data.store_list);
          } else {
            console.log("Error fetching Cart details");
          }
        } catch (error: any) {
          console.log("Error fetching Cart details", error);
          toast.error(error.response.data.message);
        }
      };
      Update_product();
    } else if(prodQuantity == minimum_qty){
      // If quantity is 1 or less, remove the product from the cart
      try {
        const response = await Remove_From_Cart(currency, id, orderId);
        if (response && response.status === 200) {
          console.log(response.data, "------------------>");
          // Fetch updated cart details after removing the product
          const Cartresponse = await View_Cart();
          if (Cartresponse && Cartresponse.status === 200) {
            dispatch(VIEW_CART(Cartresponse.data));
          }
          setState(false);
          Cart_Details();
          toast.success(response["data"]["message"]);
        } else {
          console.log("Error fetching Cart details");
        }
      } catch (error: any) {
        console.log("Error fetching Cart details", error);
        toast.error(error.response.data.message);
      }
    }
  };

  // console.log(product, "iddddddddddddddd");
  var description =
    product?.product_details?.language_data.length === 0
      ? product?.product_details?.product_description
      : languageId === "ne"
      ? product?.product_details?.language_data[0]?.product_description
      : product?.product_details?.product_description;
  const countLines = (text: string | undefined) => {
    const lines = text ? Math.ceil(text.length / 60) : 0; // Check if text is defined before accessing its length property
    return lines;
  };

  // Determine if the description exceeds a certain number of lines (e.g., 3 lines)
  const showViewMoreButton = description && description.length > 300;

  // Toggle the expanded state to show/hide the full description
  const handleToggle = () => {
    setExpanded(!expanded);
  };

  console.log(
    cartOrderId?.cart_details?.order?.order_list[quantity],
    "hello------->"
  );
  const renderCarouselItems = () => {
    const images = [
      product.product_details?.product_image,
      product.product_details?.additional_image_1,
      product.product_details?.additional_image_2,
    ].filter((image) => image); // Filter out empty image URLs

    return images.map((image, index) => (
      <div key={index} style={{ width: 300, height: 300, margin: "auto" }}>
        <Image
          src={base_url.concat(image)}
          alt={`Product Image ${index + 1}`}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    ));
  };
  const manageBrandTextLength = (text: string): string => {
    if (text.length > 30) {
      let newText = text.slice(0, 15) + "...";
      return newText;
    } else {
      return text;
    }
  };

  const renderThumbnails = () => {
    const images = [
      product.product_details?.product_image,
      product.product_details?.additional_image_1,
      product.product_details?.additional_image_2,
    ].filter((image) => image); // Filter out empty image URLs

    return images.map((image, index) => (
      <div key={index} className="thumbnail">
        <Image
          src={base_url.concat(image)}
          alt={`Thumbnail Image ${index + 1}`}
          style={{
            cursor: "pointer",
            border: activeIndex === index ? "2px solid #808080" : "none",
          }}
          onClick={() => handleThumbnailClick(index)}
        />
      </div>
    ));
  };

  const handleThumbnailClick = (index: number) => {
    setActiveIndex(index);
  };

  const handleClick = (item: any) => {
    localStorage.setItem(
      STORAGE_CONSTANTS.SHOP_ID,
      item?.product_details?.product_vendor_store?.id
    );
    console.log(item?.product_details?.product_subcategory?.id, "id========>");
    dispatch(
      SET_SELECTED_STORE_SUBCATEGORY(
        item?.product_details?.product_subcategory?.id
      )
    );
    let languageData = undefined;
    if (
      item?.product_details?.product_vendor_store?.language_data &&
      item?.product_details?.product_vendor_store?.language_data?.length > 0
    ) {
      languageData =
        item?.product_details?.product_vendor_store?.language_data[0]
          ?.store_name;
    }
    const state = {
      store_name: item?.product_details?.product_vendor_store?.store_name,
      store_type: item?.product_details?.product_vendor_store?.store_type,
      distance_in_km:
        item?.product_details?.product_vendor_store?.distance_in_km,
      language_data: languageData,
      store_rating: item?.product_details?.product_vendor_store?.store_rating,
      store_logo: item?.product_details?.product_vendor_store?.logo,
      storeId:item?.product_details?.product_vendor_store?.id
    };
    navigate("/store", { state: state });
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const renderProductDetails = () => {
    return (
      <div>
        {/* <h2 style={styles.productName}>
                 {t("productDetails.productDetails")}
                </h2> */}
        <p style={styles.tax} className={expanded ? "expanded" : ""}>
          {expanded ? description : description?.slice(0, 400)}{" "}
          {/* Add a null check for description */}{" "}
          {showViewMoreButton && (
            <Button
              className="p-0"
              variant="link"
              onClick={handleToggle}
              style={styles.view}
            >
              {expanded
                ? t("productDetails.viewLess")
                : t("productDetails.viewMore")}
            </Button>
          )}
        </p>
      </div>
    );
  };

  const renderReviews = () => {
    return (
      <div>
        {reviews.length === 0 ? (
                        <NoDataFound title="No Reviews Yet" />

        ) : (
          reviews.length > 0 && reviews.map((review: any, index: any) => (
            <div key={index} className="review">
              <div className="review-header">
                <img
                  src={review.profileImage || defaultAvatar}
                  alt={review.customer_name || anonymousUser}
                  className="profile-image"
                />
                <div>
                  <p className="name mb-0">
                    {review.customer_name || anonymousUser}
                  </p>
                  <p className="rating mb-0">
                    <Rating initialValue={review.rate} size={20} />
                  </p>
                  <p className="comment">{review.client_comment}</p>
                </div>
              </div>
             
            </div>
          ))
        )}
      </div>
    );
  };

  console.log(product?.product_details?.maximum_qty,"quantity=========>")
  return (
    <>
      <Section className="productDetails">
        <Container>
          {loading && (
            <Row>
              <Col className="d-flex align-items-center justify-content-center">
                <Spinner animation="grow" variant="primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </Col>
            </Row>
          )}
          {!loading && product.length !== 0 && (
            <Row className="py-4" style={styles.productRow}>
              <Col md={6} style={styles.productImagesWrap}>
                <FontAwesomeIcon
                  icon={
                    wishlist.includes(product?.product_details?.id)
                      ? faHeart as any
                      : faHeartOutline as any
                  }
                  onClick={() => {
                    {
                      userToken
                        ? wishlist.includes(product?.product_details?.id)
                          ? handleRemoveFromWishlist(
                              product?.product_details?.id
                            )
                          : AddToWishList(product?.product_details?.id)
                        : toast.error("Login to add products to wishlist");
                    }
                  }}
                  style={styles.wishlistIcon}
                  className="mx-2 hide-lg"
                />
                <Carousel
                  responsive={responsive}
                  ssr={true}
                  itemClass="image-item"
                  customTransition="all .5"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  additionalTransfrom={0}
                  showDots={false}
                  renderDotsOutside={false}
                  arrows={true}
                  infinite={false}
                  draggable={false}
                  swipeable={true}
                  autoPlay={false}
                  centerMode={false}
                  dotListClass=""
                  sliderClass=""
                  slidesToSlide={1}
                  keyBoardControl={true}
                  minimumTouchDrag={80}
                  partialVisible={false}
                  beforeChange={(nextSlide) => setActiveIndex(nextSlide)}
                  customLeftArrow={
                    <div className="custom-arrow left">
                      <FontAwesomeIcon icon={faAngleLeft as any} />
                    </div>
                  }
                  customRightArrow={
                    <div className="custom-arrow right">
                      <FontAwesomeIcon icon={faAngleRight as any} />
                    </div>
                  }
                >
                  {renderCarouselItems()}
                </Carousel>
                <div
                  className="thumbnail-container mt-3"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {renderThumbnails()}
                </div>
              </Col>
              <Col md={6} style={styles.productInfoWrap}>
                {/* Display product details */}
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/">{t("commonFormLabels.home")}</Link>
                  </Breadcrumb.Item>

                  <Breadcrumb.Item>
                    {/* <Image src={shopImage} width="30" /> */}
                    <p onClick={() => handleClick(product)} className="mb-0">
                      {" "}
                      {
                        product?.product_details?.product_vendor_store
                          ?.store_name
                      }
                    </p>{" "}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {product?.product_details?.product_subcategory?.name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {product?.product_details?.product_name}
                  </Breadcrumb.Item>
                </Breadcrumb>
                <h2 style={styles.productName}>
                  {product?.product_details?.language_data.length === 0
                    ? product?.product_details?.product_name
                    : languageId === "ne"
                    ? product?.product_details?.language_data[0].product_name
                    : product?.product_details?.product_name}
                </h2>
                {/* <div style={styles.distanceWrap}>
                <span style={styles.distance}>3 km</span>
                <Image src={timerImage} style={styles.distanceImage} />
              </div> */}
                <hr style={{ backgroundColor: "#D9D9D9" }} className="mb-0" />

                {product?.product_details?.current_price?.offer_type === "bogo" ? (
    <div className="bogo-wrap mb-3">
        {`Buy ${product?.product_details?.current_price.buy_quantity}, get ${
            product?.product_details?.current_price.free_product.length > 15
                ? product?.product_details?.current_price.free_product.substring(0, 15) + '...'
                : product?.product_details?.current_price.free_product
        } ${product?.product_details?.current_price.free_quantity} Free`}
    </div>
) : <div style={{minHeight:25}}></div>}
                <div className="d-flex align-items-center ">
            
                <p style={styles.qty} className="mb-0">
                  {product?.product_details?.PRDWeight}  {product?.product_details?.unit_measure} 
                </p>
                <div>
                          {product?.product_details?.brand && (
                            <h3 className="brandName mb-2 mx-2" style={{display:"inline",fontSize:14}}>
                            {product.product_details?.brand}
                            </h3>
                          )}
                        </div>
                </div>
                <div style={styles.priceWrap}>
                  {product?.product_details?.current_price ? (
                    <span style={styles.offerPrice} className="offerPrice">
                      रु
                      {parseFloat(
                        product?.product_details?.current_price?.current_price
                      ).toFixed(2)}
                    </span>
                  ) : (
                    <span style={styles.offerPrice} className="price">
                      रु
                      {parseFloat(product?.product_details?.PRDPrice).toFixed(
                        2
                      )}
                    </span>
                  )}

                  {product?.product_details?.current_price && (
                    <span style={styles.price} className="price">
                      रु{product?.product_details?.PRDPrice}
                    </span>
                  )}
                  {product?.product_details?.current_price?.discount_val && (
                    <span style={styles.offer}>
                      {product?.product_details?.current_price?.discount_val}%
                      OFF
                    </span>
                  )}
                </div>
                <p style={styles.tax}>{t("productDetails.taxes")}</p>
                <div style={styles.radioWrap} className="mt-3 product-delivery">
                  <Form.Group>
                    <Form.Check
                      inline
                      type="radio"
                      label={t("productDetails.delivery")}
                      value="delivery"
                      checked={selectedOption === "delivery"}
                      onChange={handleOptionChange}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label={t("productDetails.pickup")}
                      value="pickup"
                      checked={selectedOption === "pickup"}
                      onChange={handleOptionChange}
                    />
                  </Form.Group>
                  <FontAwesomeIcon
                    icon={
                      wishlist.includes(product?.product_details?.id)
                        ? faHeart as any
                        : faHeartOutline as any
                    }
                    onClick={() => {
                      {
                        userToken
                          ? wishlist.includes(product?.product_details?.id)
                            ? handleRemoveFromWishlist(
                                product?.product_details?.id
                              )
                            : AddToWishList(product?.product_details?.id)
                          : toast.error("Login to add products to wishlist");
                      }
                    }}
                    style={styles.wishlistIcon}
                    className="mx-2 hide-xs"
                  />
                </div>
                <div className="d-flex align-items-center justify-content-start">
                  {getQuantity(
                    product?.product_details?.product_vendor_store?.id,
                    product?.product_details?.id
                  ) > 0 && (
                    <div className="add-to-cart">
                      <button
                        className="quantity-button"
                        onClick={() =>
                          decrementQuantity(product?.product_details?.id,product?.product_details?.minimum_qty)
                        }
                      >
                        -
                      </button>
                      <span className="quantity">{prodQuantity}</span>
                      <button
                        className="quantity-button add"
                        onClick={() =>
                          incrementQuantity(product?.product_details?.id)
                        }
                        disabled={product?.product_details?.maximum_qty == prodQuantity}

                      >
                        +
                      </button>
                    </div>
                  )}
                </div>

                {outOfstock.length > 0 &&
                outOfstock.includes(product?.product_details?.id) ? (
                  <div className="d-grid gap-2 mt-3">
                    <Button
                      style={styles.btnAdd}
                      size="lg"
                      disabled
                      className="btn-outOfStock"
                    >
                      {t("commonHeader.outOfStock")}
                    </Button>
                  </div>
                ) : (
                  getQuantity(
                    product?.product_details?.product_vendor_store?.id,
                    product?.product_details?.id
                  ) <= 0 && (
                    <div className="d-grid gap-2 mt-3">
                      <Button
                        className="btnBlue"
                        onClick={() => handleCart(product?.product_details?.id,product?.product_details?.minimum_qty)}
                      >
                        {t("productDetails.addToCart")}
                      </Button>
                    </div>
                  )
                )}
                 {/* {product?.product_details?.maximum_qty > 0 && prodQuantity > 0 &&
                      product?.product_details?.minimum_qty !== prodQuantity
                       && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            textAlign:"left",
                          }}
                          className="minmax"
                        >
                          MAX {product?.product_details?.maximum_qty} QUANTITY
                        </p>
                      )}
                    {product?.product_details?.minimum_qty > 0 && prodQuantity > 0 &&
                      product?.product_details?.minimum_qty === prodQuantity && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            textAlign:"left",
                          }}
                          className="minmax"
                        >
                          MIN {product?.product_details?.minimum_qty} QUANTITY
                        </p>
                      )} */}
                      {outOfstock.includes(product?.product_details?.id) ? "" : <p style={{
                            color: "red",
                            fontSize: "small",
                          }}>{handleShowInventoryLabel(product?.product_details?.minimum_qty,product?.product_details?.maximum_qty,product?.product_details?.available,prodQuantity)}</p>}
              </Col>
            </Row>
          )}
          {!loading && product.length !== 0 && (
            // <Row className="my-5 productDetailsRow">
            //   <Col>
            //     <h2 style={styles.productName}>
            //       {t("productDetails.productDetails")}
            //     </h2>
            //     <p style={styles.tax} className={expanded ? "expanded" : ""}>
            //       {expanded ? description : description?.slice(0, 400)}{" "}
            //       {/* Add a null check for description */}{" "}
            //       {showViewMoreButton && (
            //         <Button
            //           className="p-0"
            //           variant="link"
            //           onClick={handleToggle}
            //           style={styles.view}
            //         >
            //           {expanded
            //             ? t("productDetails.viewLess")
            //             : t("productDetails.viewMore")}
            //         </Button>
            //       )}
            //     </p>
            //   </Col>
            // </Row>
            <Row className="my-5 productDetailsRow">
              <Col className="p-0">
                <Tabs defaultActiveKey="details" id="product-details-tabs">
                  <Tab
                    eventKey="details"
                    title={t("productDetails.productDetails")}
                    className="py-3"
                  >
                    {renderProductDetails()}
                  </Tab>
                  <Tab eventKey="reviews" title={t("userProfile.productReviews")} className="py-3">
                    {renderReviews()}
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          )}
        </Container>
      </Section>
      <Section
        style={{
          backgroundColor: "#F0F6FF",
          paddingTop: 30,
          paddingBottom: 30,
        }}
      >
        <Container>
          <Row>
            <PeopleAlsoBought />
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default ProductDetails;
