import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface UnauthorizedModalProps {
  show: boolean;
  handleClose: () => void;
}

const UnauthorizedModal: React.FC<UnauthorizedModalProps> = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} centered className="logoutModal">
      <Modal.Header closeButton>
        <Modal.Title>Unauthorized Access</Modal.Title>
      </Modal.Header>
      <Modal.Body>You do not have permission to access this page.</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UnauthorizedModal;
