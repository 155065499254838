import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Image, Badge } from "react-bootstrap";
import New from "../../assets/images/new.png";
import ShopImage from "../../assets/images/default-shop.png";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartOutline } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./ProductList.style";
import { base_url } from "../../Api/ApiConst";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/Store";
import productId, { SET_SELECTED_PRODUCT } from "../../Redux/productId";
import { useNavigate } from "react-router-dom";
import {
  Add_To_Cart,
  Add_To_Wishlist,
  Delete_Wishlist,
  Remove_From_Cart,
  Update_Cart,
  User_Last_Viewed,
  View_Cart,
  View_Wishlist,
} from "../../Api";
import { toast } from "react-toastify";
import { VIEW_CART } from "../../Redux/Cart";
import { SET_PRODUCT_ID_IN_WISHLIST } from "../../Redux/User";
import {
  AddToWishList,
  fetchWishlist,
  handleRemoveFromWishlist,
} from "../../Utils/wishListUtils";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { useTranslation } from "react-i18next";
import { getOutOfStockProduct } from "../../Utils/OutOfStock";
import { getQuantity, handleShowInventoryLabel } from "../../Utils/cartUtils";
import { SET_SELECTED_STORE_ID } from "../../Redux/storeSlice";
import NoDataFound from "../NoDataFound/NoDataFound";
const isWithin48Hours = (lastProductDate: string): boolean => {
  const currentDate = new Date();
  const productCreatedDate = new Date(lastProductDate);
  const timeDiff = currentDate.getTime() - productCreatedDate.getTime();
  const hoursDiff = timeDiff / (1000 * 60 * 60); // Convert to hours
  return hoursDiff <= 48;
};

// Define the props interface

interface Category {
  id: string;
  product_name: string;
  product_image: string;
  PRDPrice: number;
  PRDWeight: string;
  product_vendor_store?: {
    id: string;
    store_name: string;
    logo: string;
  };
  current_price?: {
    current_price: string;
    discount_val: string;
    discount_type: string;
    offer_valid_to: string; // Format: YYYY-MM-DD
  };
  language_data?: {
    product_name: string;
  };
  date : string;
}

export interface ProductListProps {
  selectedSubcategory: string;
  products: any[];
}

const ProductList: React.FC<ProductListProps> = ({
  selectedSubcategory,
  products,
}) => {
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(1);
  const [limit] = useState(10); // Number of products per API call
  const [hasMore, setHasMore] = useState(true); // Track if more products are available
 
  const languageId: any = useSelector((state: RootState) => state.lan);
  const [userToken, setUserToken] = React.useState<string | null>("");
  const [currency, setCurrency] = useState("NPR");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [orderId, setOrderId] = useState<any>({});
  const [state, setState] = useState<boolean>();
  const wishlist: any = useSelector(
    (state: RootState) => state.User.productIdInWishList
  );
  const userLatitude = useSelector(
    (state: RootState) => state.Location.selected_latitude
  );
  const userLongitude = useSelector(
    (state: RootState) => state.Location.selected_longitude
  );
  const outOfstock = useSelector((state: RootState) => state.offer.OutOfStock);
  const [prodQuantity, setProdQuantity] = useState<number>(1);
  const cartData = useSelector((state: RootState) => state.Cart.View_Cart);

  // console.log(outOfstock, "console======>");

  useEffect(() => {
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    getOutOfStockProduct();
  }, []);

  useEffect(() => {
    fetchWishlist();
  }, [userToken]);

  if (products.length === 0) {
    return (
      // <div
      //   className="d-flex align-items-center justify-content-center noData mb-3"
      //   style={{ height: "100%" }}
      // >
      //   <h3 style={styles.noProduct}>
      //     <Image
      //       src={noProduct}
      //       className="mx-2"
      //       style={{ width: 32, height: 32 }}
      //     />{" "}
      //     {t("commonHeader.noProductsFoundInYourArea")}
      //   </h3>
      // </div>
      //     <div className="no-product-card">
      //     <Image src="https://img.freepik.com/free-vector/adorable-dog-sitting-cushion_1308-146036.jpg?t=st=1719924497~exp=1719928097~hmac=4139cf4ce495c31f86af56332dd29e33f033f91a94d59cd7aafebe9cf98e9d86&w=740" alt="No Product" className="no-product-image" />
      //     <h2>No Products Found</h2>
      // </div>
      <NoDataFound title={t("commonHeader.noProductsFoundInYourArea")} />
    );
  }

  const handleClick = (id: any) => {
    dispatch(SET_SELECTED_PRODUCT(id));
    {userToken && User_Last_Viewed(id)};
    navigate("/product-details");
  };

  const handleCart = (
    id: any,
    storeId: any,
    store_name: any,
    language_data: any,
    store_logo: any,
    store_rating: any,
    minimum: any
  ) => {
    const addToCart = async () => {
      try {
        const response = await Add_To_Cart(id, currency, minimum);
        if (response && response.status === 200) {
          console.log(response.data, "jhhhhhhhhhhhhhhh");

          localStorage.setItem(STORAGE_CONSTANTS.SHOP_ID, storeId);
          dispatch(SET_SELECTED_STORE_ID(storeId));
          toast.success(response["data"]["message"]);
          localStorage.setItem(
            STORAGE_CONSTANTS.ORDER_ID,
            response?.data?.order_id
          );
          const state = {
            store_name: store_name,
            language_data: language_data,
            store_logo: store_logo,
            storeId: storeId,
            store_rating: store_rating,
          };
          navigate("/store", { state: state });
          const Cartresponse = await View_Cart();
          if (Cartresponse && Cartresponse.status === 200) {
            console.log(Cartresponse.data, "kkkkkkkkkkkk");
            dispatch(VIEW_CART(Cartresponse.data));
            // console.log(Cartresponse.data,"99999999999999")
          }
        } else {
          toast.error(response["data"]["message"]);
        }
      } catch (error: any) {
        console.log("Error occurred:", error);
      }
    };
    addToCart();
  };

  
  const incrementQuantity = (id: any, ProductQuantity: any) => {
    const Update_product = async () => {
      try {
        const response = await Update_Cart(
          currency,
          ProductQuantity + 1,
          id,
          cartData?.cart_details?.order?.id
        );
        if (response && response.status === 200) {
          console.log(response.data, "------------------>");
          const Cartresponse = await View_Cart();
          if (Cartresponse && Cartresponse.status === 200) {
            dispatch(VIEW_CART(Cartresponse.data));
          }
          setState(true);
          // Cart_Details();
          if (response.data.error) {
            toast.error(response["data"]["error"]);
          } else {
            // toast.success(response["data"]["message"]);
            toast.success(response["data"]["message"]);
          } // setStores(response.data.store_list);
        } else {
          console.log("Error fetching Cart details");
        }
      } catch (error: any) {
        console.log("Error fetching Cart details", error);
        toast.error(error?.response?.data?.message);
      }
    };
    Update_product();
  };

  const decrementQuantity = async (
    id: any,
    ProductQuantity: any,
    minimum: any
  ) => {
    if (ProductQuantity > minimum) {
      // If quantity is greater than 1, decrement quantity
      const Update_product = async () => {
        try {
          const response = await Update_Cart(
            currency,
            ProductQuantity - 1,
            id,
            cartData?.cart_details?.order?.id
          );
          if (response && response.status === 200) {
            console.log(response.data, "------------------>");
            const Cartresponse = await View_Cart();
            if (Cartresponse && Cartresponse.status === 200) {
              dispatch(VIEW_CART(Cartresponse.data));
            }
            setState(true);
            // Cart_Details();
            toast.success(response["data"]["message"]);
            // setStores(response.data.store_list);
          } else {
            console.log("Error fetching Cart details");
          }
        } catch (error: any) {
          console.log("Error fetching Cart details", error);
          toast.error(error.response.data.message);
        }
      };
      Update_product();
    } else if (ProductQuantity == minimum) {
      // If quantity is 1 or less, remove the product from the cart
      try {
        const response = await Remove_From_Cart(
          currency,
          id,
          cartData?.cart_details?.order?.id
        );
        if (response && response.status === 200) {
          console.log(response.data, "------------------>");
          // Fetch updated cart details after removing the product
          const Cartresponse = await View_Cart();
          if (Cartresponse && Cartresponse.status === 200) {
            dispatch(VIEW_CART(Cartresponse.data));
          }
          setState(false);
          // Cart_Details();
          toast.success(response["data"]["message"]);
        } else {
          console.log("Error fetching Cart details");
        }
      } catch (error: any) {
        console.log("Error fetching Cart details", error);
        toast.error(error.response.data.message);
      }
    }
  };

  const manageTextLength = (text: string): string => {
    if (text.length > 40) {
      let newText = text.slice(0, 30) + "...";
      return newText;
    } else {
      return text;
    }
  };
  const manageBrandTextLength = (text: string): string => {
    if (text.length > 11) {
      let newText = text.slice(0, 10) + "...";
      return newText;
    } else {
      return text;
    }
  };
  const isInWishlist = (id: any) => {
    return wishlist.includes(id);
  };

  const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

  // const handleClick = (item: any) => {
  //   localStorage.setItem("shop_id", item.id)
  //   const state = {
  //     store_name: item.store_name,
  //     store_type: item.store_type,
  //     distance_in_km: item.distance_in_km,
  //     language_data: item.language_data,
  //     store_rating: item.store_rating,
  //     logo: item.logo,
  //   };
  //   navigate('/store', { state: state });
  // }

  // console.log(outOfstock, "cartData===========>");

  return (
    <div>
      {/* <h3 style={styles.head}>Products for</h3> */}
      <Row>
        {products &&
          products.map((product, index) => {
            // console.log(product, "products============>");
            const ProductQuantity = getQuantity(
              product?.product_vendor_store?.id,
              product?.id
            );
            const isRecent = isWithin48Hours(product.date); 
            // console.log(isRecent, "isRecent===========>");
            return (
              <Col key={index} className="col-sm-3">
                <div style={styles.product} className="product-card">
                {isRecent &&  <Image src={New} className="mx-2 new-arrival"/> }
                  <div
                    style={styles.imageWrap}
                    key={product.id}
                    className="product-image-wrap"
                  >
                    {product?.current_price &&
                      product?.current_price.discount_type && (
                        <div
                          style={styles.offerBadgeWrap}
                          className="offer-badge"
                        >
                          <div style={styles.arrowRight}></div>
                          <p style={styles.text} className="mb-0">
                            {product.current_price.discount_type === "amount"
                              ? `${product.current_price.discount_val} Rs`
                              : `${product.current_price.discount_val} %`}
                            <br /> OFF
                          </p>
                        </div>
                      )}

{product.current_price && product.current_price.discount_type && (
        <>
          {product.current_price.offer_valid_to === today && (
            <Badge bg="danger">Ends <br></br>Today</Badge>
          )}
        </>
      )}

                    <Image
                      className="product-image"
                      src={`${base_url}/${product.product_image}`}
                      style={styles.productImage}
                      onClick={() => handleClick(product.id)}
                    />
                    <div style={styles.heartImageWrap}>
                      <FontAwesomeIcon
                        icon={
                          wishlist.includes(product?.id)
                            ? faHeart as any
                            : faHeartOutline as any
                        }
                        onClick={() => {
                          {
                            userToken
                              ? wishlist.includes(product?.id)
                                ? handleRemoveFromWishlist(product?.id)
                                : AddToWishList(product?.id)
                              : toast.error(
                                  "Login to add products to wishlist"
                                );
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div style={styles.productContent} className="productContent">
                    <div className="d-flex align-items-center mb-2 shopInfo">
                      <div
                        className="shopImageWrap"
                        style={styles.shopIconWrap}
                      >
                        <Image
                          src={
                            product?.product_vendor_store?.logo
                              ? `${base_url}/${product?.product_vendor_store?.logo}`
                              : ShopImage
                          }
                          style={styles.shopIcon}
                        />
                      </div>
                      <h2 style={styles.shopName} className="mb-0 shopName">
                        {product?.product_vendor_store?.language_data
                          ?.length === 0
                          ? product?.product_vendor_store?.store_name
                          : languageId === "ne"
                          ? product?.product_vendor_store?.language_data[0]
                              ?.store_name
                          : product?.product_vendor_store?.store_name ||
                            "Unknown Store"}
                      </h2>
                    </div>
                    <h2
                      style={styles.productName}
                      onClick={() => handleClick(product.id)}
                      className="product-name"
                    >
                      {/* {product.product_name} */}
                      {manageTextLength(
                        product?.language_data?.length === 0
                          ? product?.product_name
                          : languageId === "ne"
                          ? product?.language_data[0]?.product_name
                          : product?.product_name
                      )}
                    </h2>
                    <div className="d-flex align-items-center justify-content-between wt-brand">
                    <h3 style={styles.quantity}>{product.PRDWeight}{product.unit_measure}</h3>
                    <div>
                          {product?.brand && (
                            <h3 className="brandName">
                              {manageBrandTextLength(product.brand)}
                            </h3>
                          )}
                        </div>
                  
                    </div>
                    <div style={styles.priceWrap} className="priceWrap">
                      {product.current_price ? (
                        <span style={styles.offerPrice} className="offerPrice">
                          रु
                          {parseFloat(
                            product.current_price.current_price
                          ).toFixed(2)}
                        </span>
                      ) : (
                        <span style={styles.offerPrice} className="price">
                          रु{parseFloat(product.PRDPrice).toFixed(2)}
                        </span>
                      )}
                      {product.current_price && (
                        <span style={styles.price} className="price">
                          रु{product.PRDPrice}
                        </span>
                      )}
                    </div>
                    <div className="d-grid gap-2">
                      {outOfstock.length > 0 &&
                      outOfstock.includes(product?.id) ? (
                        <Button
                          style={styles.btnAdd}
                          size="lg"
                          disabled
                          className="btn-outOfStock"
                        >
                          {t("commonHeader.outOfStock")}
                        </Button>
                      ) : ProductQuantity > 0 ? (
                        <div className="add-to-cart">
                          <button
                            className="quantity-button"
                            onClick={() =>
                              decrementQuantity(
                                product?.id,
                                ProductQuantity,
                                product?.minimum_qty
                              )
                            }
                          >
                            -
                          </button>
                          <span className="quantity">{ProductQuantity}</span>
                          <button
                            className="quantity-button add"
                            onClick={() =>
                              incrementQuantity(product?.id, ProductQuantity)
                            }
                            disabled={product?.maximum_qty == ProductQuantity}
                          >
                            +
                          </button>
                        </div>
                      ) : (
                        <Button
                          style={styles.btnAdd}
                          size="lg"
                          onClick={() => {
                            const storeName =
                              product.language_data &&
                              product.language_data.length > 0
                                ? product.language_data[0].store_name
                                : undefined;
                            handleCart(
                              product?.id,
                              product?.product_vendor_store?.id,
                              product.product_vendor_store?.store_name,
                              storeName,
                              product?.product_vendor_store?.logo,
                              product?.product_vendor_store?.store_rating,
                              product?.minimum_qty
                            );
                          }}
                        >
                          {t("commonHeader.add")}
                        </Button>
                      )}
                    </div>
                    {/* {product?.available > 0 &&
                    product?.available == ProductQuantity ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          marginLeft: "2.5rem",
                        }}
                      >
                        ONLY {product?.available} AVAILABLE
                      </p>
                    ) : (
                      product?.maximum_qty > 0 &&
                      product?.minimum_qty == ProductQuantity && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            marginLeft: "2.5rem",
                          }}
                        >
                          MAX {product?.maximum_qty} QUANTITY
                        </p>
                      )
                    )|| product?.available > 0 &&
                    product?.available == ProductQuantity ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          marginLeft: "2.5rem",
                        }}
                      >
                        ONLY {product?.available} AVAILABLE
                      </p>
                    ) : (
                      product?.minimum_qty > 0 &&
                      product?.minimum_qty == ProductQuantity && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            marginLeft: "2.5rem",
                          }}
                        >
                          MIN {product?.minimum_qty} QUANTITY
                        </p>
                      )
                    )} */}
                    {ProductQuantity > 0 && <p style={{
                            color: "red",
                            fontSize: "small",
                            marginLeft: "2.5rem",
                          }}>{handleShowInventoryLabel(product?.minimum_qty,product?.maximum_qty,product?.available,ProductQuantity)}</p>}
                  </div>
                </div>
              </Col>
            );
          })}
      </Row>
    </div>
  );
};

export default ProductList;
