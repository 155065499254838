import { CSSProperties } from "react";
import { Right } from "react-bootstrap/lib/Media";

export interface Styles {
  btnFooter: CSSProperties;
  para: CSSProperties;
  footerHeading: CSSProperties;
  navItem:CSSProperties;
}

const styles: Styles = {
  
  btnFooter: {
    width:240,
   borderWidth:1,
   borderRadius:16,
   backgroundColor:"transparent",
   paddingTop:15,
   paddingBottom:15,
   borderColor:'var(--white-color)',
   borderStyle:"solid",
   color:"var(--white-color)",
   fontFamily: "var(--primary-font-semibold)",
   fontSize:16,
   
  },
  para: {
    marginTop:25,
    marginBottom:30,
    // width:270,
    fontFamily: "var(--primary-font-medium)",
    color: "var(--white-color)",
    fontSize: 16,
    lineHeight:1.3
  },
  footerHeading :{
    color:"var(--white-color)",
    fontFamily: "var(--primary-font-semibold)",
    fontSize:20,
    marginTop:30,
    marginBottom:30
  },
  navItem:{
    color:"var(--white-color)",
    fontFamily: "var(--primary-font-medium)",
    fontSize:16,
    listStyle:"none",
    marginTop:10
  }
};

export default styles;
