import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';

const FullScreenLoader = () => {
  return (
    <Modal
      show={true}
      centered
      backdrop="static"
      keyboard={false}
      contentClassName="bg-transparent border-0"
    >
      <Modal.Body className="d-flex justify-content-center">
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
          style={{ color: 'var(--primary-color)' }}
        />
      </Modal.Body>
    </Modal>
  );
};

export default FullScreenLoader;