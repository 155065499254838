import React, { useEffect, useState } from "react";
import Section from "../../Components/Section/Section";
import {
  Button,
  Col,
  Container,
  Image,
  Row,
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  Modal,
  Card,
  Accordion,
  Spinner,
  Alert,
  Badge,
} from "react-bootstrap";
import Heading from "../../Components/Heading/Heading";
import { getStyles } from "./ViewCart.style";
import { FaArrowRight, FaRegClock } from "react-icons/fa";
import ShopIcon from "../../assets/images/default-shop.png";
import Free from "../../assets/images/free.png";
import OfferIcon from "../../assets/images/percent.png";
import ProductImage from "../../assets/images/aata.png";
import BillingIcon from "../../assets/images/billingInfo.png";
import {
  faHome,
  faTrash,
  faBuilding,
  faLocationDot,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import {
  Add_Address,
  COD_Payment,
  Coupons,
  Delete_Address,
  Delivery_Method,
  Edit_Address,
  getProductAvailability,
  Remove_From_Cart,
  Saved_Address,
  Update_Cart,
  User_Last_Viewed,
  View_Cart,
} from "../../Api";
import { useDispatch, useSelector } from "react-redux";
import {
  CLEAR_CART,
  Pickup_Address,
  VIEW_CART,
  selected_pickup,
} from "../../Redux/Cart";
import { base_url } from "../../Api/ApiConst";
import { RootState } from "../../Redux/Store/Store";
import { toast } from "react-toastify";
import noProduct from "../../assets/images/no-product.png";
import AddressRadio from "../../Components/AddressRadio/AddressRadio";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BroughtTogether from "../../Components/BroughtTogether/BroughtTogether";
import { SET_USER_ADDRESS } from "../../Redux/User";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { rest } from "lodash";
import lottieEmptyCart from "../../assets/lotties/emptyCart.json";
import { Player } from "@lottiefiles/react-lottie-player";
import NoDataFound from "../../Components/NoDataFound/NoDataFound";
import { ConsoleView } from "react-device-detect";
import { handleShowInventoryLabel } from "../../Utils/cartUtils";
import { SET_SELECTED_PRODUCT } from "../../Redux/productId";
import { Autocomplete, LoadScript } from "@react-google-maps/api";
import { reverseGeocode } from "../../Utils/LocationFinder";

const initialFormData = {
  deliveryInstructions: "",
  addressType: "",
};
interface Product {
  product_id: number;
  product_name: string;
  available: number;
  is_low_stock: boolean;
  product_image: string;
}

const ViewCart: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = getStyles(isMobile);
  const [formData, setFormData] = useState(initialFormData);
  const [userToken, setUserToken] = React.useState<string | null>("");
  const [products, setProducts] = useState<any>("");
  const [currency, setCurrency] = useState("NPR");
  const [state, setState] = useState<boolean>();
  const [amount, setAmount] = useState<any>("");
  const [addresses, setAddresses] = useState<any[]>([]);
  const [storeErrors, setStoreErrors] = useState({
    address: "",
  });
  const breadcrumbs = [
    <Link to="/" key="home">
      Home
    </Link>,
    "Cart",
  ];
  const [showBillingForm, setShowBillingForm] = useState<boolean>(false); // State to toggle billing form visibility
  const [showAddresses, setShowAddresses] = useState<boolean>(false); // State to toggle address visibility
  const [showModal, setShowModal] = useState(false);
  let supplyMethod = "Only Delivery"; // Example supplyMethod value
  const [selectedOption, setSelectedOption] = useState<any>(
    localStorage.getItem(STORAGE_CONSTANTS.PickupOption) || ""
  );
  const [selectedOptions, setSelectedOptions] = useState<any>(
    addresses[0] || {}
  );
  // const [selectedPickupOptions, setSelectedPickupOptions] = useState<any>([]);
  const selectedPickupOptions = useSelector(
    (state: RootState) => state.Cart.SelectedPickupOptions
  );

  const [storeType, setStoreType] = useState<string>("");
  const [selectedStoreId, setSelectedStoreId] = useState<any>("");
  const [storeId, setStoreId] = useState<any>("");
  const [showAllCoupons, setShowAllCoupons] = useState(false);
  const [couponList, setCouponList] = useState<any>([]);
  const [orderId, setOrderId] = useState("");
  const [couponAmount, setCouponAmount] = useState<any>(null);
  const [selectedCoupon, setSelectedCoupon] = useState<any>([]);
  const [limit, setLimit] = useState<any>("");
  const [applyCoupon, setApplyCoupon] = useState<any>("");
  const [address, setAddress] = useState<any>("");
  const libraries: any = ["places"];
  const googleMapsApiKey: any = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  // const [couponId, ] = useState<any>(true);
  const languageId: any = useSelector((state: RootState) => state.lan);
  const [autocomplete, setAutocomplete] = useState<
    google.maps.places.Autocomplete | any
  >(null);
  const [suggestions, setSuggestions] = useState<any>([]);
  // const [selectedAddress, setSelectedAddress] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [userError, setUserError] = useState<{
    // store_name?: string;
    type?: string;
  }>({});

  const isLoggedIn = useSelector((state: RootState) => state.login.isLoggedIn);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [unavailableProducts, setUnavailableProducts] = useState<number[]>([]);
  const [activeCoupon, setActiveCoupon] = useState<string | null>(null);

  const schema = yup.object().shape({
    firstName: yup.string().required(t("responseMessage.fnRequired")),
    landMark: yup.string().required(t("responseMessage.addressRequired")),
    city: yup.string(),
    state: yup.string(),
    email: yup
      .string()
      .required(t("responseMessage.emailRequired"))
      .matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        t("responseMessage.validEmail")
      ),
    lastName: yup.string().required(t("responseMessage.lnRequired")),
    zipCode: yup.string(),
    phoneNumber: yup
      .string()
      .required(t("responseMessage.phoneRequired"))
      .matches(/^\d{10}$/, t("responseMessage.phoneInvalid")),
  });

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  useEffect(() => {
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    Cart_Details();
    handleCoupon();
    setSelectedCoupon(localStorage.getItem(STORAGE_CONSTANTS.COUPON_CODE));
    // setSelectedPickupOptions()
    const savedOption = localStorage.getItem(STORAGE_CONSTANTS.PickupOption);
    if (savedOption) {
      setSelectedOption(savedOption);
    }
    // if(couponId){
    //   handleCoupons(couponId)
    // }
    // setUserNum(localStorage.getItem(STORAGE_CONSTANTS.USER_NUMBER))
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Check if selectedPickupOptions is populated and orderId is set
    if (selectedPickupOptions.length !== 0 && orderId) {
      const processDeliveryMethods = async () => {
        for (const item of selectedPickupOptions) {
          // Ensure each item has a non-empty selectedOption before calling
          if (item.selectedOption) {
            await DeliveryMethod(orderId, item.id, item.selectedOption);
          } else {
            console.warn(
              `Selected option is empty for item with id: ${item.id}`
            );
          }
        }
      };
      processDeliveryMethods();
    }
  }, [orderId]);

  const handleLoad = (autocompleteInstance: any) => {
    setAutocomplete(autocompleteInstance);
  };

  const handleSearchInputChange = (event: any) => {
    const newData = event.target.value;
    setAddress(newData);
    if (newData === "") {
      setStoreErrors((storeErrors) => ({
        ...storeErrors,
        address: `* This field is required`,
      }));
    } else {
      setStoreErrors((storeErrors) => ({ ...storeErrors, address: "" }));
    }
    const service = new window.google.maps.places.AutocompleteService();
    service.getPlacePredictions(
      { input: event.target.value },
      (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setSuggestions(predictions);
          // console.log("predictions--->", predictions);
        }
      }
    );
  };

  const handlePlaceChanged = async () => {
    const details = await autocomplete.getPlace();
    setAddress(details.formatted_address);

    try {
      const { lat, lng } = details.geometry.location;
      const latitude = typeof lat === "function" ? lat() : null;
      const longitude = typeof lng === "function" ? lng() : null;
      // console.log(latitude, "lat----------->");

      if (details?.geometry && details?.geometry?.location) {
        handleStartReverseGeocoding(latitude, longitude, googleMapsApiKey);
      }
    } catch (error) {
      // console.log("error in selectLocation--->", error);
    }
  };

  const handleStartReverseGeocoding = async (
    latitude: any,
    longitude: any,
    apiKey: any
  ) => {
    // console.log("latitude-->", latitude);
    // console.log("kkkkkkkkkk-->", apiKey);

    try {
      const location: any = await reverseGeocode(latitude, longitude, apiKey);
      // console.log(location, "loac=======>");
      if (Object.keys(location)?.length > 0) {
        // setCity(location.cityName);
        // setPincode(location.pincode);
        reset({
          city: location.cityName ?? "",
          zipCode: location.pincode ?? "",
          state: location.state ?? "",
        });
        // setStoreLat(location.lat);
        // setStoreLong(location.long);
      }
      // setLoader(() => false);
      // navigation.goBack();
    } catch (error) {
      console.error("Error during reverse geocoding:", error);
      // setLoader(() => false);
      throw error;
    }
  };

  useEffect(() => {
    if (addresses.length > 0) {
      setSelectedOptions(addresses[0]);
    }
  }, [addresses]);

  const {
    register,
    formState: { errors },
    control,
    reset,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const getDeliveryOptions = (storeType: string) => {
    let deliveryOptions = [];

    if (storeType.toLocaleLowerCase() === "only delivery") {
      deliveryOptions.push({ _id: "1", value: "Delivery" });
    } else if (storeType.toLocaleLowerCase() === "pick and delivery") {
      deliveryOptions.push({ _id: "1", value: "Delivery" });
      deliveryOptions.push({ _id: "2", value: "Pickup" });
    } else {
      deliveryOptions.push({ _id: "2", value: "Pickup" });
    }
    return deliveryOptions;
  };

  const get_total = (el: any) => {
    // console.log("DDDDDDDDDDDDDDDDDDDDDDDDD", el);
    let val: any = 0;
    Object.values(el).map((ele: any, index: any) =>
      ele.map((e: any, i: any) => {
        val += parseFloat(e?.product.PRDPrice) * parseFloat(e?.quantity);
      })
    );
    // console.log(parseFloat(val).toFixed(2), "val===========>");
    return parseFloat(val).toFixed(2);
  };

  const Cart_Details = async () => {
    try {
      setLoading(true);
      const response = await View_Cart();
      if (response && response.status === 200) {
        // console.log(response.data, "hgggggggggggggggggggggggj");
        setOrderId(response.data.cart_details.order.id);
        setProducts(response.data.cart_details.order.order_list);
        setAmount(response.data.cart_details.order.order_details);
        setCouponAmount(response.data.cart_details);
        setSelectedCoupon(response?.data?.cart_details?.code);
        const cartDataItems: any =
          response.data?.cart_details?.order?.order_list;
        let pickupArray: any[] = [];

        Object.keys(cartDataItems).map((key) => {
          const foundPickupOption = selectedPickupOptions.find(
            (ele: any) => ele?.id == key
          );
          const productsForStore = cartDataItems[key];
          let storePickupOptions: any;
          let deliveryMethodFromResponse: any;

          if (productsForStore?.length > 0) {
            storePickupOptions = getDeliveryOptions(
              productsForStore[0]?.product?.product_vendor_store?.store_type
            );
            deliveryMethodFromResponse = productsForStore[0]?.delivery_method;
          }

          if (deliveryMethodFromResponse) {
            let currentSelectedOptionValue = "";
            if (deliveryMethodFromResponse === "PICKED_UP") {
              currentSelectedOptionValue = "Pick Up";
            } else if (deliveryMethodFromResponse === "DELIVERY") {
              currentSelectedOptionValue = "Delivery";
            }
            const obj = {
              id: key,
              selectedOption: currentSelectedOptionValue ?? "",
            };
            pickupArray.push(obj);
          } else if (
            foundPickupOption?.id &&
            foundPickupOption?.selectedOption
          ) {
            pickupArray.push(foundPickupOption);
          } else if (storePickupOptions.length === 1) {
            const obj = {
              id: key,
              selectedOption: storePickupOptions[0]?.value,
            };

            pickupArray.push(obj);
          } else {
            const Obj = {
              id: key,
              selectedOption: "Delivery",
            };
            pickupArray.push(Obj);
          }
        });
        dispatch(selected_pickup(pickupArray));
      } else {
        console.log("Error fetching Cart details");
      }
    } catch (error: any) {
      console.log("Error fetching Cart details", error);
      // setProducts("")
    } finally {
      setLoading(false);
    }
  };

  // console.log(selectedPickupOptions,"option============>")

  const SavedAddresses = async () => {
    try {
      const response = await Saved_Address(userToken);
      if (response && response.status === 200) {
        // console.log(response.data.list_of_user_address, "saved ----------->");
        setAddresses(response.data.list_of_user_address);
      } else {
        console.log("Error fetching Saved Address");
      }
    } catch (error) {
      console.log("Error fetching Saved Address", error);
    }
  };

  useEffect(() => {
    if (userToken) {
      SavedAddresses();
    }
  }, [userToken]);

  const handleSubmits = async (data: any) => {
    let formIsValid = true;

    if (address === "") {
      setStoreErrors((storeErrors) => ({
        ...storeErrors,
        address: `* This field is required`,
      }));
      formIsValid = false;
    }

    if (data && formIsValid) {
      try {
        const response = await Add_Address(userToken, formData, data, address);
        if (response && response.status === 201) {
          // console.log(response.data, "added address ----------->");
          SavedAddresses();
          setShowAddressModal(false);
          toast.success(response["data"]["messsage"]);
          reset();
          setFormData(initialFormData);
        } else {
          console.log("Error fetching Added Address");
        }
      } catch (error: any) {
        console.log("Error fetching Added Address", error);
        toast.error(error.response.data.error_message);
        setUserError(error.response.data.error);
      }
      console.log("Form submitted:", formData);
    }
  };

  const incrementQuantity = (id: any, order: any, quantity: any) => {
    const Update_product = async () => {
      try {
        const response = await Update_Cart(currency, quantity + 1, id, order);
        if (response && response.status === 200) {
          const Cartresponse = await View_Cart();
          if (Cartresponse && Cartresponse.status === 200) {
            dispatch(VIEW_CART(Cartresponse.data));
          }
          setState(true);
          Cart_Details();
          if (response?.data?.error) {
            toast.error(response?.data?.error);
            setLimit(response?.data?.error);
          } else {
            toast.success(response["data"]["message"]);
          }
        } else {
          console.log("Error fetching Cart details");
        }
      } catch (error: any) {
        console.log("Error fetching Cart details", error);
        toast.error(error.response.data.message);
        
      }
    };
    Update_product();
  };

  const decrementQuantity = async (
    id: any,
    order: any,
    quantity: any,
    minimum_qty: any
  ) => {
    // console.log(id, "id-------------->");
    if (quantity > minimum_qty) {
      const Update_product = async () => {
        try {
          const response = await Update_Cart(currency, quantity - 1, id, order);
          if (response && response.status === 200) {
            const Cartresponse = await View_Cart();
            if (Cartresponse && Cartresponse.status === 200) {
              dispatch(VIEW_CART(Cartresponse.data));
            }
            setState(true);
            Cart_Details();
            toast.success(response["data"]["message"]);
            // setStores(response.data.store_list);
          } else {
            console.log("Error fetching Cart details");
          }
        } catch (error: any) {
          console.log("Error fetching Cart details", error);
          toast.error(error.response.data.message);
        }
      };
      Update_product();
    } else if (quantity === minimum_qty) {
      try {
        const response = await Remove_From_Cart(currency, id, order);
        if (response && response.status === 200) {
          const Cartresponse = await View_Cart();
          if (Cartresponse && Cartresponse.status === 200) {
            dispatch(VIEW_CART(Cartresponse.data));
          }
          setState(false);
          Cart_Details();
          toast.success(response["data"]["message"]);
        } else {
          console.log("Error fetching Cart details");
        }
      } catch (error: any) {
        console.log("Error fetching Cart details", error);
        setProducts("");
        toast.error(error.response.data.message);
      }
    }
  };
  // const [items, setItems] = useState<string[]>([]);

  //   const handleCheckoutClick = () => {

  // alert();
  //     const foundEle = selectedPickupOptions.find(
  //       (ele: any) => ele.selectedOption === ""
  //     );
  //     if (!foundEle) {
  //       if (isLoggedIn === true) {
  //         setShowAddresses((prevState) => !prevState);
  //         dispatch(Pickup_Address(selectedPickupOptions));
  //       } else {
  //         localStorage.setItem(STORAGE_CONSTANTS.CHECKOUT_STATUS, "true");
  //         navigate("/login");
  //       }
  //     } else {
  //       toast.error("Select Delivery Option");
  //     }
  //   };

  const checkProductAvailability = async (orderId: string) => {
    try {
      const availabilityResponse = await getProductAvailability(orderId);
      if (availabilityResponse.status) {
        const outOfStockProducts = availabilityResponse.product_list.filter(
          (product: Product) => product.available === 0
        );

        if (outOfStockProducts.length > 0) {
          setUnavailableProducts(
            outOfStockProducts.map((product: Product) => product.product_id)
          );
          toast.error("Some products are out of stock");
          console.log("Out of stock products:", outOfStockProducts);
          return false;
        } else {
          return true;
        }
      } else {
        toast.error(
          availabilityResponse.message || "Failed to check product availability"
        );
        return false;
      }
    } catch (error) {
      console.error("Error checking product availability:", error);
      toast.error("Failed to check product availability");
      return false;
    }
  };
  const handleCheckoutClick = async () => {
    console.log("Order ID:", orderId);
    console.log("Selected Pickup Options:", selectedPickupOptions);

    const isAllSelected = selectedPickupOptions.every(
      (ele: any) => ele.selectedOption !== ""
    );

    if (isAllSelected) {
      if (isLoggedIn) {
        const isAvailable = await checkProductAvailability(orderId);

        if (isAvailable) {
          setShowAddresses((prevState) => !prevState);
          dispatch(Pickup_Address(selectedPickupOptions));
        }
      } else {
        localStorage.setItem(STORAGE_CONSTANTS.CHECKOUT_STATUS, "true");
        navigate("/login");
      }
    } else {
      toast.error("Select Delivery Option for all items");
    }
  };

  const handleOptionChange = (e: any) => {
    const updatedPickupOptions = selectedPickupOptions.map((item: any) => {
      if (item.id === selectedStoreId) {
        return { id: item.id, selectedOption: e.target.value };
      }
      return item;
    });

    DeliveryMethod(orderId, storeId, e.target.value);
    dispatch(selected_pickup(updatedPickupOptions));
    setShowModal(false);
  };

  const DeliveryMethod = async (
    orderId: any,
    storeId: any,
    selectedDeliveryMethod: any
  ) => {
    try {
      const response = await Delivery_Method(
        orderId,
        storeId,
        selectedDeliveryMethod
      );
      if (response && response.status === 200) {
      } else {
        console.log("Error");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleOpenModal = (type: any, item: any, id: any) => {
    setStoreType(type);
    setSelectedStoreId(item);
    setStoreId(id);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleAddressChange = (index: number) => {
    const updatedAddresses = addresses.map((address, i) => ({
      ...address,
      checked: i === index,
    }));
    setAddresses(updatedAddresses);
  };

  const handleDelete = (id: any) => {
    const DeleteAddress = async () => {
      try {
        const response = await Delete_Address(userToken, id);
        if (response && response.status === 200) {
          SavedAddresses();
          toast.success(response["data"]["messsage"]);
        } else {
          console.log("Error fetching Saved Address");
          toast.error(response["data"]["messsage"]);
        }
      } catch (error) {
        console.log("Error fetching Saved Address", error);
      }
    };
    DeleteAddress();
  };

  const handleAddress = (address: any) => {
    setSelectedOptions(address);
  };

  const handleEdit = (id: any) => {
    const EditAddress = async () => {
      try {
        const response = await Edit_Address(id);
        if (response && response.status === 200) {
          SavedAddresses();
          toast.success(response["data"]["messsage"]);
        } else {
          console.log("Error fetching Saved Address");
          toast.error(response["data"]["messsage"]);
        }
      } catch (error) {
        console.log("Error fetching Saved Address", error);
      }
    };
    EditAddress();
  };

  const handleCoupon = () => {
    const CouponsList = async () => {
      try {
        const response = await Coupons();
        if (response && response.status === 200) {
          console.log(response.data, "couponsd----------->");
          setCouponList(response.data.coupon_list);
        } else {
          console.log("Error fetching Saved Address");
        }
      } catch (error) {
        console.log("Error fetching Coupons", error);
      }
    };
    CouponsList();
  };

  // const handleNext = () => {
  //   console.log("selectedOption--->>", selectedOptions);
  //   if (Object.keys(selectedOptions).length !== 0) {
  //     dispatch(SET_USER_ADDRESS(selectedOptions));
  //     navigate("/confirm-order");
  //   } else {
  //     toast.error("Please select an address to continue");
  //   }
  // };

  const handleNext = async () => {
    console.log("selectedOption--->>", selectedOptions);

    if (Object.keys(selectedOptions).length !== 0) {
      try {
        const availabilityResponse = await getProductAvailability(orderId);

        if (availabilityResponse.status) {
          const outOfStockProducts = availabilityResponse.product_list.filter(
            (product: Product) => product.available === 0
          );
          console.log(outOfStockProducts, "ksdjfksdfjksdfksdfjk");
          if (outOfStockProducts.length > 0) {
            setUnavailableProducts(
              outOfStockProducts.map((product: Product) => product.product_id)
            );
            toast.error("Some products are out of stock");
            console.log("Out of stock products:", outOfStockProducts);
          } else {
            setUnavailableProducts([]);
            dispatch(SET_USER_ADDRESS(selectedOptions));
            navigate("/confirm-order");
          }
        } else {
          toast.error(
            availabilityResponse.message ||
            "Failed to check product availability"
          );
        }
      } catch (error) {
        console.error("Error checking product availability:", error);
        toast.error("Failed to check product availability");
      }
    } else {
      toast.error("Please select an address to continue");
    }
  };

  const [showAddressModal, setShowAddressModal] = useState(false);

  const handleAddButtonClick = () => {
    setShowAddressModal(true); // Open the modal
    const userNumber = localStorage.getItem(STORAGE_CONSTANTS.USER_NUMBER);
    if (userNumber) {
      reset({
        phoneNumber: userNumber,
      });
    }
  };

  const handleCloseAddressModal = () => {
    setShowAddressModal(false);
  };

  const handleCoupons = async (coupon: any) => {
    try {
      const payload = {
        currency: "NPR",
        coupon: coupon,
        order_id: orderId,
        submit: false,
      };
      const response: any = await COD_Payment(payload);

      if (!response?.error) {
        if (response.data.status === false) {
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);
          setActiveCoupon(coupon.code); // Set active coupon

          localStorage.setItem(STORAGE_CONSTANTS.COUPON_CODE, coupon);
          setSelectedCoupon(coupon);
          const Cartresponses = await View_Cart();
          if (Cartresponses && Cartresponses.status === 200) {
            console.log(Cartresponses.data?.cart_details, "ddrrtd");
            setCouponAmount(Cartresponses.data.cart_details);
            dispatch(VIEW_CART(Cartresponses.data));
          }
        }
        // console.log("data----->", response.data);
      }
      // setShowLoader(false);
    } catch (error) {
      // setShowLoader(false);
      console.log(error);
    }
  };

  const removeCoupon = async () => {
    try {
      setActiveCoupon(null);
      setSelectedCoupon(null);
      localStorage.removeItem(STORAGE_CONSTANTS.COUPON_CODE);

      const payload = {
        currency: "NPR",
        coupon: "",
        order_id: orderId,
        submit: false,
        remove_coupon: true,
      };

      const response: any = await COD_Payment(payload);

      if (response && !response.error) {
        if (response.data.status === false) {
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);

          const Cartresponses = await View_Cart();
          if (Cartresponses && Cartresponses.status === 200) {
            setCouponAmount(Cartresponses.data.cart_details);
            dispatch(VIEW_CART(Cartresponses.data));
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewMoreCoupons = () => {
    setShowAllCoupons(true);
  };
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  // console.log(couponAmount, "coupon======>");
  const handleClick = (id: any) => {
    dispatch(SET_SELECTED_PRODUCT(id));
    User_Last_Viewed(id);
    navigate("/product-details");
  };

  return (
    <>
      <Section className="viewCart">
        <Container>
          <Row>
            <Col>
              <div className="breadcrumbs">
                {breadcrumbs.map((crumb, index) => (
                  <React.Fragment key={index}>
                    <span
                      style={{
                        color: index === 0 ? "var(--primary-color)" : "#BEB8B8",
                        fontFamily: "var(--primary-font-medium)",
                        fontSize: 16,
                      }}
                    >
                      {crumb}
                    </span>
                    {index !== breadcrumbs.length - 1 && (
                      <FaArrowRight
                        className="mx-2"
                        style={{ color: "#BEB8B8" }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </Col>
          </Row>
          <Row className="py-4">
            <Heading text={t("cart.yourCart")} alignment="left" />
          </Row>
        </Container>
        <div style={styles.whiteWrap} className="sm-whiteWrap">
          <Container className="below">
            {loading && Object.keys(products).length === 0 && (
              <Row>
                <Col className="d-flex align-items-center justify-content-center">
                  <Player
                    src={lottieEmptyCart}
                    loop
                    autoplay
                    className="player"
                    style={{ height: "140px", width: "140px" }}
                  />
                </Col>
              </Row>
            )}
            {!loading && Object.keys(products).length === 0 ? (
              <NoDataFound title={t("cart.emptyCart")} />
            ) : (
              <Row style={styles.cartFirst} className="cart-row">
                <Col
                  sm={7}
                  style={styles.cartLeftCol}
                  className="cart-col-left"
                >
                  {Object.keys(products).map((item: any, index) => {


                    let Pickup = selectedPickupOptions.find(
                      (element: any) => element?.id === item
                    )?.selectedOption;
                    const hasOutOfStockProducts = products[item].some(
                      (ele: any) =>
                        unavailableProducts.includes(ele.product.product_id)
                    );
                    return (
                      <div
                        style={{
                          ...styles.cartItem,
                          backgroundColor: hasOutOfStockProducts
                            ? "rgba(234, 67, 53, 0.25)"
                            : "transparent", // Highlight out-of-stock products
                        }}
                        key={index}
                      >
                        {/* <h3 style={styles.cartTitle}>Shipping From</h3> */}
                        <div
                          className="d-flex align-items-center justify-content-between "
                          style={styles.shopWrap}
                        >
                          <div className="d-flex align-items-center justify-content-start">
                            <Image
                              src={
                                products[item][0]?.product?.product_vendor_store
                                  ?.logo
                                  ? `${base_url}/${products[item][0]?.product?.product_vendor_store?.logo}`
                                  : ShopIcon
                              }
                              className="hide-xs"
                              style={{ width: 34 }}
                            />
                            <p className="mb-0 mx-2" style={styles.cartTitle}>
                              {products[item][0]?.product?.product_vendor_store
                                ?.language_data.length === 0
                                ? products[item][0]?.product
                                  ?.product_vendor_store?.store_name
                                : languageId === "ne"
                                  ? products[item][0]?.product
                                    ?.product_vendor_store?.language_data[0]
                                    ?.store_name
                                  : products[item][0]?.product
                                    ?.product_vendor_store?.store_name}
                            </p>
                          </div>
                          <Form.Group controlId="supplyMethod">
                            <div
                              style={styles.supplyMethod}
                              onClick={() =>
                                handleOpenModal(
                                  products[item][0]?.product
                                    ?.product_vendor_store?.store_type,
                                  item,
                                  products[item][0]?.product
                                    ?.product_vendor_store?.id
                                )
                              }
                            >
                              <p style={styles.supplyMethodText}>
                                {" "}
                                {Pickup === ""
                                  ? t("cart.selectDeliveryMethod")
                                  : Pickup}
                              </p>
                            </div>

                            <Modal
                              show={showModal}
                              onHide={handleCloseModal}
                              centered
                              className="supplyMethodModal"
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>
                                  {t("cart.supplyModalTitle")}
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                {storeType === "Only Pick Up" ||
                                  storeType === "Pick and Delivery" ? (
                                  <Form.Check
                                    type="radio"
                                    id="pickup"
                                    name="deliveryOption"
                                    label={t("cart.pickup")}
                                    value="Pick Up"
                                    checked={
                                      selectedPickupOptions.find(
                                        (element: any) =>
                                          element?.id === selectedStoreId
                                      )?.selectedOption === "Pick Up"
                                    }
                                    onChange={(e) => handleOptionChange(e)}
                                  />
                                ) : null}
                                {storeType === "Only Delivery" ||
                                  storeType === "Pick and Delivery" ? (
                                  <Form.Check
                                    type="radio"
                                    id="delivery"
                                    name="deliveryOption"
                                    label={t("cart.delivery")}
                                    value="Delivery"
                                    checked={
                                      selectedPickupOptions.find(
                                        (element: any) =>
                                          element?.id === selectedStoreId
                                      )?.selectedOption === "Delivery"
                                    }
                                    onChange={(e) => handleOptionChange(e)}
                                  />
                                ) : null}
                              </Modal.Body>
                            </Modal>
                          </Form.Group>
                        </div>
                        {products &&
                          products[item].map((ele: any) => {
                            const isFreeProduct = ele?.is_free_product; // Check if the product is free
                            // console.log(ele, "element------->");
                            return (
                              <Row
                                className={`d-flex align-items-center cart-item-row ${isFreeProduct ? "free-product" : ""
                                  }`}
                                style={styles.oneCart}
                                key={ele?.product?.id}
                              >
                                <Col sm={2} className="cartImageCol">
                                {ele?.is_free_product ? (
                                    <div className="freeIcon">
                                      <Image
                                      src={Free}
                                      
                                      // style={styles.productImage}
                                    /></div>
                                ) : null}
                                  <div
                                    style={styles.productImageWrap}
                                    onClick={() =>
                                      handleClick(ele?.product?.id)
                                    }
                                  >
                                    <Image
                                      src={
                                        `${base_url}/${ele?.product?.product_image}` ||
                                        ProductImage
                                      }
                                      style={styles.productImage}
                                    />
                                  </div>
                                  {/* {ele?.product?.maximum_qty > 0 &&
                                    ele?.quantity > 0 &&
                                    ele?.product?.minimum_qty !==
                                      ele?.quantity && (
                                      <p
                                        style={{
                                          color: "red",
                                          fontSize: "small",
                                        }}
                                      >
                                        MAX {ele?.product?.maximum_qty} QUANTITY
                                      </p>
                                    )}
                                  {ele?.product?.minimum_qty > 0 &&
                                    ele?.quantity > 0 &&
                                    ele?.product?.minimum_qty ===
                                      ele?.quantity && (
                                      <p
                                        style={{
                                          color: "red",
                                          fontSize: "small",
                                        }}
                                      >
                                        MIN {ele?.product?.minimum_qty} QUANTITY
                                      </p>
                                    )} */}
                                  <p
                                    style={{
                                      color: "red",
                                      fontSize: "small",
                                    }}
                                  >
                                    {handleShowInventoryLabel(
                                      ele?.product?.minimum_qty,
                                      ele?.product?.maximum_qty,
                                      ele?.product?.available,
                                      ele?.quantity
                                    )}
                                  </p>
                                </Col>
                                <Col sm={4} className="cartProductInfoCol">
                                  {ele?.store_order_status.can_change}
                                  <div style={styles.productNameWt}>
                                    <h3
                                      style={styles.productName}
                                      onClick={() =>
                                        handleClick(ele?.product?.id)
                                      }
                                    >
                                      {/* {ele?.product?.product_name || ""} */}
                                      {ele?.product?.language_data.length === 0
                                        ? ele?.product?.product_name
                                        : languageId === "ne"
                                          ? ele?.product?.language_data[0]
                                            ?.product_name
                                          : ele?.product?.product_name}
                                    </h3>
                                  </div>
                                </Col>
                                <Col sm={3} className="cartQuantityCol text-center">
                                  {ele?.is_free_product ? (
                                   
                                     <span className="quantity">
                                        {ele?.quantity}
                                      </span>
                                  ) : (
                                    <div className="add-to-cart justify-content-center">
                                      <button
                                        className="quantity-button"
                                        onClick={() =>
                                          decrementQuantity(
                                            ele?.product?.id,
                                            ele?.order,
                                            ele?.quantity,
                                            ele?.product?.minimum_qty
                                          )
                                        }
                                      >
                                        -
                                      </button>
                                      <span className="quantity">
                                        {ele?.quantity}
                                      </span>
                                      <button
                                        className="quantity-button add"
                                        onClick={() =>
                                          incrementQuantity(
                                            ele?.product?.id,
                                            ele?.order,
                                            ele?.quantity
                                          )
                                        }
                                        disabled={
                                          ele?.product?.maximum_qty ==
                                          ele?.quantity
                                        }
                                      >
                                        +
                                      </button>
                                    </div>
                                  )}

                                </Col>

                                <Col sm={3} className="cartPriceCol">
                                  {/* <div style={styles.productPrice}>
                                    रु{ele?.price || ""}&nbsp;&nbsp; 
                                    { <span style={styles.value600}>
                          रु{ele?.product?.PRDPrice}
                          <span
                            style={{ ...styles.value500, color: "#808080",textDecorationLine:"line-through" }}
                          ></span>}
                                  </div> */}
                                  {ele?.product?.current_price ? (
                                    <span style={styles.value600}>
                                      रु{ele?.price}&nbsp;&nbsp;
                                      <span
                                        style={{
                                          ...styles.value500,
                                          color: "#808080",
                                          textDecorationLine: "line-through",
                                        }}
                                      >
                                        रु{ele?.product?.PRDPrice}
                                      </span>
                                    </span>
                                  ) : (
                                    <span style={styles.productPrice}>
                                      {ele?.price}
                                    </span>
                                  )}
                                </Col>
                              </Row>
                            );
                          })}
                      </div>
                    );
                    // {limit && (
                    //   <span style={styles.error}>{limit}</span>
                    // )}
                  })}
                </Col>

                {!loading && Object.keys(products).length !== 0 && (
                  <Col
                    sm={5}
                    style={styles.cartRightCol}
                    className="cart-col-right"
                  >
                    <h3 style={styles.cartTitle}>{t("cart.orderSummary")}</h3>
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <span style={styles.value500}>
                        {t("cart.mrpTotal")} &nbsp;&nbsp;
                        <br />
                        {Number(get_total(products)) >
                          Number(amount?.amount) && (
                            <span
                              style={{
                                fontSize: "0.85rem",
                                width: "5rem",

                                fontFamily: "sans-serif bold",
                                color: "blue",
                                marginBottom: "2rem",
                                // border: "0.2px solid blue",
                                // backgroundColor: "lightgray",
                                // borderRadius: "6px",
                              }}
                            >
                              {t("cart.itemSavings")} रु
                              {(Number(get_total(products)) - amount?.amount).toFixed(2)}

                            </span>
                          )}
                      </span>
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <span style={styles.value600}>
                          रु{amount?.amount}
                          <br />
                          <span style={styles.value500}>
                            {Number(get_total(products)) >
                              Number(amount?.amount) && (
                                <span
                                  style={{
                                    ...styles.value500,
                                    color: "#808080",
                                    textDecorationLine: "line-through",
                                  }}
                                >
                                  रु{get_total(products)}
                                </span>
                              )}
                          </span>
                        </span>
                      </div>
                    </div>
                    {selectedCoupon && couponAmount?.value && (
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <span style={styles.value500}>
                          {"Coupon Discount"} ({selectedCoupon})
                        </span>
                        <span style={{ ...styles.value600, color: "green" }}>
                          - रु{couponAmount?.value}
                        </span>
                      </div>
                    )}
                    <hr />
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <span style={styles.value600}>{t("cart.toPay")}</span>

                      {selectedCoupon && couponAmount?.value ? ( // Check if a coupon is applied
                        <span style={styles.value600}>
                          रु{couponAmount?.total}&nbsp;&nbsp;
                          <span
                            style={{
                              ...styles.value500,
                              color: "#808080",
                              textDecorationLine: "line-through",
                            }}
                          >
                            रु{amount?.sub_total}
                          </span>
                        </span>
                      ) : (
                        <span style={styles.value600}>
                          रु{amount?.sub_total}{" "}
                          {/* Show the original price if no coupon is applied */}
                        </span>
                      )}
                    </div>

                    {couponList && (
                      <div>
                        {couponList
                          .slice(0, showAllCoupons ? couponList.length : 1)
                          .map((coupon: any) => (
                            <div
                              key={coupon.id}
                              className="d-flex align-items-center justify-content-between my-3"
                            >
                              <div className="d-flex align-items-center justify-content-start">
                                <Image
                                  src={OfferIcon}
                                  style={{ width: 35, marginRight: 10 }}
                                />
                                <div>
                                  <div style={styles.value600}>
                                    {coupon.code}
                                  </div>
                                  <div
                                    style={{ ...styles.value500, fontSize: 12 }}
                                  >
                                    {coupon.discount_value}% OFF upto रु
                                    {coupon.max_amount}
                                  </div>
                                  <div>
                                    <p style={{ fontSize: "small" }}>
                                      {t("cart.offerInfo")}{" "}
                                      {coupon.min_purchase}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <Button
                                style={{
                                  ...styles.applyBtn,
                                  borderColor:
                                    selectedCoupon === coupon.code
                                      ? "#ea4335"
                                      : styles.applyBtn?.borderColor,
                                  backgroundColor:
                                    selectedCoupon === coupon.code
                                      ? "#ea4335"
                                      : styles.applyBtn?.backgroundColor,
                                }}
                                onClick={() => {
                                  if (selectedCoupon === coupon.code) {
                                    removeCoupon(); // Remove coupon if it's already selected
                                  } else {
                                    handleCoupons(coupon.code); // Apply coupon if not selected
                                  }
                                }}
                              >
                                {selectedCoupon === coupon.code
                                  ? "Remove"
                                  : "Apply"}
                              </Button>
                            </div>
                          ))}
                        <hr />
                        {couponList.length > 1 && !showAllCoupons && (
                          <div className="text-center my-0">
                            <Button
                              variant="link"
                              onClick={handleViewMoreCoupons}
                              style={{ textDecoration: "none" }}
                            >
                              <span
                                style={{ ...styles.value600, fontSize: 14 }}
                              >
                                {t("cart.moreCoupons")}
                              </span>
                            </Button>
                          </div>
                        )}
                      </div>
                    )}

                    {!showAddresses && (
                      <div className="justify-content-center d-flex mt-5">
                        <Button
                          onClick={handleCheckoutClick}
                          className="btnBlue sm-checkout"
                          style={{ minWidth: 250 }}
                        >
                          {" "}
                          {t("cart.checkout")}
                        </Button>
                      </div>
                    )}
                    <div className="justify-content-center d-flex mt-2">
                      <Link
                        to="/"
                        style={{
                          ...styles.value500,
                          color: "var(--primary-color) !important",
                        }}
                      >
                        {t("cart.continueShopping")}
                      </Link>
                    </div>
                  </Col>
                )}
              </Row>
            )}

            <Row>
              {Object.keys(products).length !== 0 && showAddresses && (
                <>
                  <div className="mt-4">
                    <h3 className="mb-3" style={styles.cartTitle}>
                      {t("cart.selectAddress")}
                    </h3>
                    <Row
                      className="mb-3"
                      style={{ display: "flex", flexWrap: "wrap" }}
                    >
                      {addresses &&
                        addresses.map((address, index) => (
                          <Col
                            sm={4}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginTop: 10,
                            }}
                          >
                            <div style={styles.wrap}>
                              <input
                                type="radio"
                                name="address"
                                value={address}
                                checked={selectedOptions === address}
                                onChange={() => handleAddress(address)}
                              // defaultChecked={index === 0} // Call handleAddressChange on change event
                              />
                              <span style={styles.addressTitle}>
                                {address.type}
                              </span>
                              <FontAwesomeIcon
                                icon={faHome as any}
                                style={styles.addressIcon}
                              />
                            </div>
                            <div style={styles.addressDescription}>
                              <button
                                onClick={handleShowDeleteModal}
                                style={styles.trash}
                              >
                                <FontAwesomeIcon
                                  icon={faTrash as any}
                                  style={styles.trashIcon}
                                />
                              </button>
                              <div>
                                <Modal
                                  centered
                                  className="deleteAddressModal"
                                  show={showDeleteModal}
                                  onHide={handleCloseDeleteModal}
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title>
                                      {" "}
                                      {t("deleteModal.confirmDeletion")}
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    {t("deleteModal.confirmDeletionInfo")}
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button
                                      variant="secondary"
                                      onClick={handleCloseDeleteModal}
                                    >
                                      {t("deleteModal.cancel")}
                                    </Button>
                                    <Button
                                      variant="danger"
                                      onClick={() => {
                                        // Handle deletion logic here
                                        handleDelete(address.id);
                                        handleCloseDeleteModal(); // Close the modal after deletion
                                      }}
                                    >
                                      {t("deleteModal.delete")}
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                              </div>
                              <div style={styles.addressDescriptionText}>
                                <b>
                                  {address.first_name} {address.last_name}
                                </b>{" "}
                                <br></br>
                                {address.address_str} <br></br>
                                {address.city} {address.state} <br></br>
                                {address.country} {address.pincode}{" "}
                              </div>
                            </div>
                          </Col>
                        ))}
                      <Col
                        sm={4}
                        style={{ marginTop: 40 }}
                        className="addAddressWrap"
                      >
                        <div style={styles.wrap} className="innerWrap">
                          <div
                            style={{
                              ...styles.addressDescription,
                              width: "100%",
                              textAlign: "center",
                            }}
                          >
                            <p
                              style={{
                                ...styles.addressDescriptionText,
                                fontWeight: "500",
                                marginBottom: 20,
                              }}
                            >
                              {t("cart.addAddress")}
                            </p>
                            <Button
                              className="btnAdd"
                              style={styles.max400}
                              onClick={handleAddButtonClick}
                            >
                              <FontAwesomeIcon icon={faPlus as any} className="mr-2" />
                            </Button>
                            <Modal
                              size="lg"
                              show={showAddressModal}
                              onHide={handleCloseAddressModal}
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>
                                  <div className="d-flex align-items-center justify-content-start ">
                                    <Image
                                      src={BillingIcon}
                                      style={{ width: 32 }}
                                    />
                                    <h3
                                      style={styles.cartTitle}
                                      className="mx-2 mb-0"
                                    >
                                      {t("cart.billingInfo")}
                                    </h3>
                                  </div>
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <Form
                                  onSubmit={handleSubmit(handleSubmits)}
                                  className="billingInfo"
                                >
                                  <Row>
                                    <Col>
                                      <FormGroup controlId="formFirstName">
                                        <FormLabel className="d-block">
                                          {t("commonFormLabels.firstName")}
                                        </FormLabel>
                                        <FormControl
                                          type="text"
                                          placeholder={t(
                                            "commonFormLabels.firstName"
                                          )}
                                          // name="firstName"
                                          // value={formData.firstName}
                                          // onChange={handleChange}
                                          // required
                                          {...register("firstName")}
                                        />
                                        {errors.firstName && (
                                          <span style={styles.error}>
                                            {errors.firstName.message}
                                          </span>
                                        )}
                                      </FormGroup>
                                    </Col>
                                    <Col>
                                      <FormGroup controlId="formLastName">
                                        <FormLabel className="d-block">
                                          {t("commonFormLabels.lastName")}
                                        </FormLabel>
                                        <FormControl
                                          type="text"
                                          placeholder={t(
                                            "commonFormLabels.lastName"
                                          )}
                                          // name="lastName"
                                          // value={formData.lastName}
                                          // onChange={handleChange}
                                          // required
                                          {...register("lastName")}
                                        />
                                        {errors.lastName && (
                                          <span style={styles.error}>
                                            {errors.lastName.message}
                                          </span>
                                        )}
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <FormGroup controlId="formEmail">
                                        <FormLabel className="d-block">
                                          {t("commonFormLabels.email")}
                                        </FormLabel>
                                        <FormControl
                                          type="email"
                                          placeholder={t(
                                            "commonFormLabels.email"
                                          )}
                                          // name="email"
                                          // value={formData.email}
                                          // onChange={handleChange}
                                          // required
                                          {...register("email")}
                                        />
                                        {errors.email && (
                                          <span style={styles.error}>
                                            {errors.email.message}
                                          </span>
                                        )}
                                      </FormGroup>
                                    </Col>
                                    <Col>
                                      <FormGroup controlId="formPhoneNumber">
                                        <FormLabel className="d-block">
                                          {t("commonFormLabels.phoneNumber")}
                                        </FormLabel>
                                        <FormControl
                                          type="text"
                                          placeholder={t(
                                            "commonFormLabels.phoneNumber"
                                          )}
                                          // name="phoneNumber"
                                          // value={formData.phoneNumber}
                                          // onChange={handleChange}
                                          // required
                                          // maxLength={10}
                                          {...register("phoneNumber")}
                                        />
                                        {errors.phoneNumber && (
                                          <span style={styles.error}>
                                            {errors.phoneNumber.message}
                                          </span>
                                        )}
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <Form.Group controlId="searchAddress">
                                        <Form.Label className="d-block">
                                          {"Address"}
                                        </Form.Label>
                                        <div className="locationAutoComplete">
                                          <LoadScript
                                            googleMapsApiKey={googleMapsApiKey}
                                            libraries={libraries}
                                          >
                                            <Autocomplete
                                              onLoad={handleLoad}
                                              onPlaceChanged={
                                                handlePlaceChanged
                                              }
                                            >
                                              <div id="location-popup">
                                                <Form.Control
                                                  id="location-search"
                                                  type="text"
                                                  placeholder="Search for places..."
                                                  className="btnWhite"
                                                  value={address}
                                                  onChange={
                                                    handleSearchInputChange
                                                  }
                                                />
                                                {storeErrors.address && (
                                                  <span style={styles.error}>
                                                    {storeErrors.address}
                                                  </span>
                                                )}
                                              </div>
                                            </Autocomplete>
                                          </LoadScript>
                                        </div>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <FormGroup controlId="formlandmark">
                                        <FormLabel className="d-block">
                                          {"Landmark"}
                                        </FormLabel>
                                        <FormControl
                                          as="textarea"
                                          placeholder={"Enter your landmark"}
                                          // name="address"
                                          // value={formData.address}
                                          // onChange={handleChange}
                                          // required
                                          {...register("landMark")}
                                        />
                                        {errors.landMark && (
                                          <span style={styles.error}>
                                            {errors.landMark.message}
                                          </span>
                                        )}
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <FormGroup controlId="formCity">
                                        <FormLabel className="d-block">
                                          {t("commonFormLabels.city")}
                                        </FormLabel>
                                        <FormControl
                                          type="text"
                                          placeholder={t(
                                            "commonFormLabels.city"
                                          )}
                                          // name="city"
                                          // value={formData.city}
                                          // onChange={handleChange}
                                          // required
                                          {...register("city")}
                                          disabled
                                        />
                                        {/* {errors.city && (
                                          <span style={styles.error}>
                                            {errors.city.message}
                                          </span>
                                        )} */}
                                      </FormGroup>
                                    </Col>
                                    <Col>
                                      <FormGroup controlId="formState">
                                        <FormLabel className="d-block">
                                          {t("commonFormLabels.state")}
                                        </FormLabel>
                                        {/* <FormControl
                                    as="select"
                                    name="state"
                                    value={formData.state}
                                    onChange={handleChange}
                                    required
                                  >
                                    <option value="">Select State</option>
                                    <option value="mh">MH</option>
                                  </FormControl>  */}
                                        <FormControl
                                          type="text"
                                          placeholder={t(
                                            "commonFormLabels.state"
                                          )}
                                          // name="state"
                                          // value={formData.state}
                                          // onChange={handleChange}
                                          // required
                                          {...register("state")}
                                          disabled
                                        />
                                        {/* {errors.state && (
                                          <span style={styles.error}>
                                            {errors.state.message}
                                          </span>
                                        )} */}
                                      </FormGroup>
                                    </Col>
                                    <Col>
                                      <FormGroup controlId="formZipCode">
                                        <FormLabel className="d-block">
                                          {t("commonFormLabels.zipCode")}
                                        </FormLabel>
                                        <FormControl
                                          type="text"
                                          placeholder={t(
                                            "commonFormLabels.zipCode"
                                          )}
                                          // name="zipCode"
                                          // value={formData.zipCode}
                                          // onChange={handleChange}
                                          // required
                                          // // maxLength={6}
                                          // maxLength={2}
                                          {...register("zipCode")}
                                          disabled
                                        />
                                        {/* {errors.zipCode && (
                                          <span style={styles.error}>
                                            {errors.zipCode.message}
                                          </span>
                                        )} */}
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  {/* <FormGroup controlId="formDeliveryInstructions">
                                    <FormLabel className="d-block">
                                      {t(
                                        "commonFormLabels.deliveryInstruction"
                                      )}
                                    </FormLabel>
                                    <FormControl
                                      as="textarea"
                                      placeholder={t(
                                        "commonFormLabels.deliveryInstruction"
                                      )}
                                      name="deliveryInstructions"
                                      value={formData.deliveryInstructions}
                                      onChange={handleChange}
                                    />
                                  </FormGroup> */}
                                  <FormGroup
                                    controlId="formAddressType"
                                    className="address-type"
                                  >
                                    <FormLabel className="d-block">
                                      {t("commonFormLabels.addressType")}
                                    </FormLabel>
                                    <div className="btn-group type-btn">
                                      <Button
                                        className={`btn ${formData.addressType === "home"
                                            ? "btn-primary"
                                            : "btn-light"
                                          }`}
                                        onClick={() =>
                                          setFormData((prev) => ({
                                            ...prev,
                                            addressType: "home",
                                          }))
                                        }
                                      >
                                        {t("commonFormLabels.home")}
                                      </Button>
                                      <Button
                                        className={`btn ${formData.addressType === "work"
                                            ? "btn-primary"
                                            : "btn-light"
                                          }`}
                                        onClick={() =>
                                          setFormData((prev) => ({
                                            ...prev,
                                            addressType: "work",
                                          }))
                                        }
                                      >
                                        {t("commonFormLabels.work")}
                                      </Button>
                                      <Button
                                        className={`btn ${formData.addressType === "other"
                                            ? "btn-primary"
                                            : "btn-light"
                                          }`}
                                        onClick={() =>
                                          setFormData((prev) => ({
                                            ...prev,
                                            addressType: "other",
                                          }))
                                        }
                                      >
                                        {t("commonFormLabels.other")}
                                      </Button>
                                    </div>
                                    <br />
                                    {userError.type && (
                                      <span className="err">
                                        {userError.type}
                                      </span>
                                    )}
                                  </FormGroup>
                                  <Row className="align-items-center justify-content-center d-flex my-3">
                                    <Button
                                      className="btnBlue"
                                      style={{ maxWidth: 400 }}
                                      type="submit"
                                    >
                                      {t("cart.addAddress")}
                                    </Button>
                                  </Row>
                                </Form>
                              </Modal.Body>
                            </Modal>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <Row
                    className="justify-content-center"
                    style={{ margin: "auto" }}
                  >
                    <Button
                      className="btnBlue"
                      style={styles.max400}
                      onClick={() => handleNext()}
                    >
                      {t("cart.continue")}
                    </Button>
                  </Row>
                </>
              )}
            </Row>
            <Row className="mt-5">
              <BroughtTogether />
            </Row>
          </Container>
        </div>
      </Section>
    </>
  );
};

export default ViewCart;
