import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
    paymentList : [],
    userSelectedMethod : []
};

export const Payment = createSlice({
    name: "Payment",
    initialState,
    reducers: {
        SET_PAYMENT_LINK: (state, action) => {
            state.paymentList = action.payload;
        },
        SET_USER_SELECTED_METHOD: (state, action) => {
            state.userSelectedMethod = action.payload;
        },
        REMOVE_USER_SELECTED_METHOD: (state, action) => {
            state.userSelectedMethod = []
        },
        
    },
});


export const { SET_PAYMENT_LINK, SET_USER_SELECTED_METHOD, REMOVE_USER_SELECTED_METHOD } = Payment.actions;
export default Payment.reducer;