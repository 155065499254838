import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row, Form, Button, Image, Spinner } from 'react-bootstrap';
import styles from './PrivacyPolicy.style';
import TitleBar from '../../Components/TitleBar/TitleBar';
import TitleImage from '../../assets/images/terms.png';
import Section from '../../Components/Section/Section';

import career from "../../assets/images/career.png";
import Dot from "../../assets/images/dot.png";
import _ from 'lodash'
import { useNavigate } from 'react-router-dom';
import Heading from '../../Components/Heading/Heading';
const PrivacyPolicy: React.FC = () => {
  const breadcrumbs: string[] = ['Home', 'Privacy Policy'];
  const title: string = 'Privacy Policy';
  const info: string = "";
  const imageUrl: string = TitleImage; // Corrected imageUrl



  const navigate = useNavigate()

  useEffect(() => {

    window.scrollTo(0, 0);
  }, []);





  return (
    <div className="terms-conditions common-page">

      <TitleBar breadcrumbs={breadcrumbs} title={title} info={info} imageUrl={imageUrl} />
      <Section className="termsConditions" style={{ paddingTop: 100, paddingBottom: 100 }}>
        <Container>
          <Row>
            <Col>
              <p style={styles.text}><strong>Effective Date:</strong> [Insert Date]</p>

              <h4>1. Introduction</h4>
              <p style={styles.text}>
                Welcome to <strong>Sajilo Life Pvt. Ltd.</strong> (“Company”, “we”, “our”, “us”). We value the trust you place in us and recognize the importance of secure transactions and information privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and mobile applications (collectively, the “Platform”). By accessing or using our services, you agree to this privacy policy and consent to our collection, use, disclosure, retention, and protection of your personal information as described herein. If you have any questions or concerns about this policy or our practices, please contact us at <a href="mailto:>customer.support@sajilolife.com"> customer.support@sajilolife.com.</a>
              </p>
              <h4>Information We Collect</h4>
              <p style={styles.text}>
                We collect information about you in various ways, including:
              </p>
              <ul style={styles.text}>
                <li><strong>Personal Information:</strong> Name, email address, phone number, billing address, shipping address, and other similar information necessary for account creation, order processing, and customer support.</li>
                <li><strong>Payment Information:</strong> Payment instrument details (e.g., credit card number, wallet, bank account), and security codes required for secure payment processing. This information is collected through secure payment gateways and is not stored directly by Sajilo Life.</li>
                <li><strong>Account Information:</strong> Username, password (hashed and salted for security), and other credentials used to establish and manage your Sajilo Life account.</li>
                <li><strong>Usage Data:</strong> Information about how you interact with the Platform, such as pages viewed, products searched and purchased, and browsing history. This data is used to personalize your experience and improve our services.</li>
                <li><strong>Device Information: </strong>Device type, operating system, IP address, browser type, and other technical data that helps us understand how users access the Platform and troubleshoot any technical issues.</li>
                <li><strong>Location Data:</strong> If you enable location services on your device, we may collect your location information to provide location-based services (e.g., suggesting local sellers) or for fraud prevention purposes. We will always obtain your consent before collecting or using your location data.</li>
                <li><strong>Behavioral Data:</strong> Information about your buying behavior, preferences, and other information you choose to provide on our Platform.</li>
              </ul>

              <h4>How We Use Your Information</h4>
              <p style={styles.text}>We use your information for various purposes, including:</p>
              <ul style={styles.text}>
                <li><strong>Account Management:</strong> To create and manage your account.</li>
                <li><strong>Order Processing:</strong> To process and fulfill your orders.</li>
                <li><strong>Customer Support:</strong> To provide customer support and respond to your inquiries.</li>
                <li><strong>Marketing Communications:</strong> To send you marketing and promotional communications, including personalized offers and recommendations.</li>
                <li><strong>Service Improvement:</strong> To improve our website, mobile apps, and services.</li>
                <li><strong>Security: </strong>To protect our services and prevent fraud.</li>
                <li><strong>Legal Compliance:</strong> To comply with legal obligations and respond to legal requests.</li>
              </ul>
              <h4>Marketing and Promotional Communications</h4>
              <p style={styles.text}>
                We may use your personal information to send you marketing and promotional communications. These communications may include information about our products, services, promotions, offers, and events that we believe may be of interest to you. We may send these communications via email, SMS, push notifications, and in-app messages.
              </p>
              <ul>
                <li><strong>Personalization:</strong> We may personalize our marketing communications based on your preferences, purchase history, and interactions with our website and mobile apps.</li>
                <li><strong>Opting Out:</strong> You can opt out of receiving marketing communications at any time by following the unsubscribe instructions in the emails, adjusting your mobile device settings, or changing your preferences in the app settings.</li>
                <li><strong>Third-Party Marketing:</strong> We do not share your personal information with third parties for their marketing purposes without your explicit consent.</li>
              </ul>
              <h4>Sharing Your Information</h4>
              <p style={styles.text}>We may share your information with third parties in the following circumstances:</p>
              <ul>
                <li><strong>Service Providers:</strong> We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, email delivery, and hosting services.</li>
                <li><strong>Business Transfers:</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
                <li><strong>Legal Obligations:</strong> We may disclose your information to comply with legal obligations, respond to legal requests, or protect our rights.</li>

              </ul>
              <h4>Security of Your Information</h4>
              <p style={styles.text}>We use administrative, technical, and physical security measures to protect your personal information. These measures include: </p>
              <ul>
                <li><strong> SSL Encryption:</strong> We use Secure Sockets Layer (SSL) technology to encrypt the transmission of information between your browser and our servers. This helps protect your personal information from unauthorized access during transmission.</li>
                <li><strong>Access Controls:</strong> We restrict access to your personal information to authorized personnel only.</li>
                <li><strong>Data Storage: </strong>We use secure servers and data storage solutions to protect your information from unauthorized access, use, or disclosure.</li>
              </ul>

              <p style={styles.text}>While we strive to use commercially acceptable means to protect your personal information, no method of transmission over the Internet or method of electronic storage is 100% secure.</p>

              <h4>Your Privacy Rights</h4>
              <p style={styles.text}>
                Depending on your location, you may have certain rights regarding your personal information, including the right to access, correct, or delete your personal information, and the right to restrict or object to certain processing activities. To exercise these rights, please contact us at <a href="mailto:>customer.support@sajilolife.com"> customer.support@sajilolife.com.</a>
              </p>

              <h4>Cookies and Tracking Technologies</h4>
              <p style={styles.text}>We use cookies and similar tracking technologies to track the activity on our Platform and hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Platform.</p>

              <h4> Data Retention</h4>
              <p style={styles.text}>We retain your personal information for as long as necessary to fulfill the purposes outlined in this privacy policy, unless a longer retention period is required or permitted by law. This includes retaining your information for marketing purposes until you opt out or withdraw your consent. </p>

              <h4>Compliance with GDPR and Other International Laws</h4>
              <p style={styles.text}>We comply with the General Data Protection Regulation (GDPR) and other international data protection laws. This includes:</p>
              <ul style={styles.text}>
                <li><strong>GDPR Compliance:</strong> We adhere to the GDPR principles of data minimization, storage limitation, and accountability. We ensure that personal data is processed lawfully, fairly, and transparently. We also provide data subjects with rights to access, rectify, erase, restrict, and object to the processing of their personal data.</li>
                <li><strong>EU-U.S. Privacy Shield:</strong> For data transfers from the EU to the U.S., we comply with the EU-U.S. Privacy Shield Framework, ensuring that personal data is adequately protected.</li>
                <li><strong>U.S. State Laws: </strong>We comply with relevant U.S. state privacy laws, including the California Consumer Privacy Act (CCPA), which provides California residents with rights to access, delete, and opt-out of the sale of their personal information.</li>
              </ul>
              <h4>Compliance with Data Act 2079 (2022) and Other Nepali Laws</h4>
              <p style={styles.text}>We comply with the Data Act 2079 (2022) of Nepal, which governs the collection, processing, storage, publication, and distribution of data. This includes:</p>
              <ul style={styles.text}>
                <li><strong> Data Collection: </strong>We collect data in a lawful and transparent manner, ensuring that individuals are informed about the purposes for which their data is being collected.</li>
                <li><strong>Data Processing: </strong>We process data in accordance with the principles of necessity, proportionality, and purpose limitation.</li>
                <li><strong>Data Storage: </strong>We store data securely and take appropriate measures to protect it from unauthorized access, use, or disclosure.</li>
                <li><strong>Data Publication:</strong> We ensure that any publication of data is done in a manner that respects the privacy and confidentiality of individuals.</li>
                <li><strong>Data Distribution:</strong> We distribute data only to authorized entities and ensure that data sharing complies with legal and regulatory requirements.</li>
              </ul>
              <p style={styles.text}>Additionally, we adhere to the Individual Privacy Act 2075 (2018) and the Individual Privacy Regulation 2077 (2020), which safeguard the fundamental right to privacy guaranteed by the Constitution of Nepal. These laws include provisions on data collection, storage, and disclosure, and require the consent of individuals before collecting their personal information.</p>



              <h4>Changes to This Privacy Policy</h4>
              <p style={styles.text}>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>


              <h4>Contact Us</h4>
              <p style={styles.text}>
                If you have any questions or comments about this Privacy Policy, please contact us at:
                <strong>Sajilo Life Pvt. Ltd.</strong>
                Pepsicola, Kathmandu, Nepal<br></br>
                <strong>Email:</strong><a href="mailto:>customer.support@sajilolife.com"> customer.support@sajilolife.com.</a><br></br>
                <strong>Phone:</strong> <a href="tel:+9779708073716">+977-9708073716</a>

              </p>



            </Col>
          </Row>


        </Container>
      </Section>
    </div>
  );
};

export default PrivacyPolicy;
