import React, { useCallback, useEffect, useState, useRef } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  Button,
  Image,
  Spinner,
  FormControl,
  Overlay,
  Badge,
} from "react-bootstrap";
import styles from "./NearByProducts.style";
import TitleBar from "../../Components/TitleBar/TitleBar";
import NearByProductsImage from "../../assets/images/nearByProducts.png";
import Section from "../../Components/Section/Section";
import shop1 from "../../assets/images/shop2.png";
import locationIcon from "../../assets/images/location.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bookmark from "../../assets/images/supply.png";
import Dot from "../../assets/images/dot.png";
import { FaArrowRight, FaSearch, FaStar } from "react-icons/fa";
import { faHeart as faHeartOutline } from "@fortawesome/free-regular-svg-icons";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import ShopImage from "../../assets/images/default-shop.png";
import { RootState } from "../../Redux/Store/Store";
import {
  Add_To_Cart,
  Add_To_Wishlist,
  Nearest_Shops,
  Top_Deals,
  Top_Deals_Suggestions,
  User_Last_Viewed,
  View_Cart,
} from "../../Api";
import { Link, useNavigate } from "react-router-dom";
import noProduct from "../../assets/images/no-product.png";
import Sort from "../../assets/images/sort.png";
import ProductImage from "../../assets/images/aata.png";
import Heart from "../../assets/images/Heart.png";
import _ from "lodash";
import { base_url } from "../../Api/ApiConst";
import { SET_SELECTED_PRODUCT } from "../../Redux/productId";
import { toast } from "react-toastify";
import { VIEW_CART } from "../../Redux/Cart";
import {
  AddToWishList,
  handleRemoveFromWishlist,
} from "../../Utils/wishListUtils";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { getOutOfStockProduct } from "../../Utils/OutOfStock";
import { useTranslation } from "react-i18next";
import { SET_SELECTED_STORE_SUBCATEGORY } from "../../Redux/User";
import InputGroup from "react-bootstrap/esm/InputGroup";
import Popover from "react-bootstrap/esm/Popover";
import NoDataFound from "../../Components/NoDataFound/NoDataFound";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import debounce from "lodash.debounce";

interface Category {
  product?: {
    id: string;
    product_name: string;
    product_image: string;
    PRDPrice: number;
    PRDWeight: string;
    current_price?: {
      discount_type: string;
      discount_val: string;
      current_price: string;
      offer_valid_to: string; 
    };
  };

  store?: {
    id: string;
    store_name: string;
    store_logo: string;
  };
}

const NearByProducts: React.FC = () => {
  const imageUrl: string = NearByProductsImage; 

  const [latitude, setLatitude] = React.useState<string | null>("");
  const [longitude, setLongitude] = React.useState<string | null>("");
  const selectedCatgeory: any = useSelector(
    (state: RootState) => state.mainCategory.selectedMainCategory
  );
  const [cateName, setCateName] = useState<string | null>("");
  const [stores, setStores] = React.useState<any>([]);
  const [userToken, setUserToken] = React.useState<string | null>("");
  const languageId: any = useSelector((state: RootState) => state.lan);
  const [loading, setLoading] = useState<boolean>(false);
  const [initialStores, setInitialStores] = useState<any>([]);
  const wishlist: any = useSelector(
    (state: RootState) => state.User.productIdInWishList
  );
  const userLatitude = useSelector(
    (state: RootState) => state.Location.selected_latitude
  );
  const userLongitude = useSelector(
    (state: RootState) => state.Location.selected_longitude
  );
  const mainName = useSelector(
    (state: RootState) => state.mainCategory.selectedMainCategory
  );
  const outOfstock = useSelector((state: RootState) => state.offer.OutOfStock);
  const [searchText, setSearchText] = useState<string>("");
  const [sortBy, setSortBy] = useState<string>("");
  const { t, i18n } = useTranslation();
  const [showSortPopover, setShowSortPopover] = useState(false);
  const target = useRef(null);
  const userPincode = useSelector((state: RootState) => state.Location.pincode);

  const sortData = [
    {
      id: 1,
      Sorttype: "product_name",
      name: t("offerSortModal.productNameAsc"),
      Ascending: true,
    },
    {
      id: 2,
      Sorttype: "product_name",
      name: t("offerSortModal.productNameDesc"),
      Ascending: false,
    },
    {
      id: 3,
      Sorttype: "PRDPrice",
      name: t("storeSortModal.priceAsc"),
      Ascending: true,
    },
    {
      id: 4,
      Sorttype: "PRDPrice",
      name: t("storeSortModal.priceDesc"),
      Ascending: false,
    },
  ];

  const title: string = t("nearByProducts.nearByProducts");
  const [suggestions, setSuggestions] = React.useState<any>([]);
  const [currency, setCurrency] = useState("NPR");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const breadcrumbs = [
    <Link to="/" key="home">
      {t("footer.home")}
    </Link>,
    mainName?.language_data?.length === 0
      ? mainName?.name
      : languageId === "ne"
      ? mainName?.language_data[0]?.name
      : mainName?.name,
  ];

  useEffect(() => {
    setLatitude(localStorage.getItem(STORAGE_CONSTANTS.LATITUDE));
    setLongitude(localStorage.getItem(STORAGE_CONSTANTS.LONGITUDE));
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    getOutOfStockProduct();
  }, []);

  const fetchOffers = async () => {
    try {
      setLoading(true);
      const payload = {
        lat: userLatitude,
        long: userLongitude,
        category: selectedCatgeory?.name,
      };
      const response = await Top_Deals(payload);
      if (response && response.status === 200) {
        setStores(response.data.data);
        setInitialStores(response.data.data);
      } else {
        console.log("Error fetching Nearest Shops");
      }
    } catch (error) {
      console.log("Error fetching Nearest Shops", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (name: any) => {
    if (searchText) {
      try {
        setLoading(true);
        const payload = {
          lat: userLatitude,
          long: userLongitude,
          category: selectedCatgeory?.name,
          product_name: name ? name : searchText,
        };
        const response = await Top_Deals(payload);
        if (response && response.status === 200) {
          setStores(response.data.data);
          setInitialStores(response.data.data);
        } else {
          console.log("Error fetching Nearest Shops");
        }
      } catch (error) {
        console.log("Error fetching Nearest Shops", error);
      } finally {
        setLoading(false);
      }
    } else {
      fetchOffers();
    }
  };

  useEffect(() => {
    debouncedHandleSearch(searchText);
  }, [searchText]);

  const debouncedHandleSearch = debounce(async (searchText: any) => {
    if (searchText) {
      try {
        const payload = {
          lat: userLatitude,
          long: userLongitude,
          category: selectedCatgeory?.name,
          product_name: searchText,
          is_search: true,
        };
        const response = await Top_Deals_Suggestions(payload);
        if (response && response.status === 200) {
          const formattedStores = response.data.data.map(
            (product: any) => ({
              id: product?.id,
              img: product?.product_image,
              name: product?.product_name,
            })
          );
          setSuggestions(formattedStores);
        } else {
          console.log("Error fetching Store Product Details");
        }
      } catch (error) {
        console.log("Error fetching Store Product Details", error);
      }
    }
  }, 500);

  // const ProductSuggestions = async () => {
  //   if (searchText) {
  //     try {
  //       setLoading(true);
  //       const payload = {
  //         lat: userLatitude,
  //         long: userLongitude,
  //         category: selectedCatgeory?.name,
  //         product_name: searchText,
  //         is_search:true
  //       };
  //       const response = await Top_Deals(payload);
  //       if (response && response.status === 200) {
  //         // console.log(response.data.store_list.language_data[0].store_name);
  //         // console.log(response.data.data);
  //         setStores(response.data.data);
  //         setInitialStores(response.data.data);
  //       } else {
  //         console.log("Error fetching Nearest Shops");
  //       }
  //     } catch (error) {
  //       console.log("Error fetching Nearest Shops", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   } else {
  //     fetchOffers();
  //   }
  // };

  useEffect(() => {
    fetchOffers();
  }, []);

  const manageTextLength = (text: string): string => {
    if (text.length > 50) {
      let newText = text.slice(0, 45) + "...";
      return newText;
    } else {
      return text;
    }
  };
  const manageBrandTextLength = (text: string): string => {
    if (text.length > 11) {
      let newText = text.slice(0, 10) + "...";
      return newText;
    } else {
      return text;
    }
  };

  const handleClick = (id: any) => {
    dispatch(SET_SELECTED_PRODUCT(id));
    {
      userToken && User_Last_Viewed(id);
    }
    navigate("/product-details");
  };

  const handleCart = (
    id: any,
    storeId: any,
    store_name: any,
    subcategory: any,
    minimum: any,
    store_rating: any
  ) => {
    const addToCart = async () => {
      try {
        const response = await Add_To_Cart(id, currency, minimum);
        if (response && response.status === 200) {
          // console.log(response.data, "jhhhhhhhhhhhhhhh")
          localStorage.setItem(STORAGE_CONSTANTS.SHOP_ID, storeId);
          localStorage.setItem(
            STORAGE_CONSTANTS.ORDER_ID,
            response.data?.order_id
          );
          dispatch(SET_SELECTED_STORE_SUBCATEGORY(subcategory));
          const state = {
            store_name: store_name,
            storeId: storeId,
            store_rating: store_rating,
          };
          const Cartresponse = await View_Cart();
          if (Cartresponse && Cartresponse.status === 200) {
            // console.log(Cartresponse.data.cart_details.cart_items, "kkkkkkkkkkkk");
            dispatch(VIEW_CART(Cartresponse.data));
          }
          navigate("/store", { state: state });

          toast.success(response["data"]["message"]);
        } else {
          toast.error(response["data"]["message"]);
        }
      } catch (error: any) {
        console.log("Error occurred:", error.response.data.error_message);
      }
    };
    addToCart();
  };

  const handleWishlist = (id: any) => {
    const AddWishList = async () => {
      try {
        const response = await Add_To_Wishlist(userToken, id);
        if (response && response.status === 200) {
          console.log(response.data, "uuuuuuuuuuu");
          toast.success(response["data"]["message"]);
        } else {
          toast.error(response["data"]["message"]);
        }
      } catch (error: any) {
        console.log("Error occurred:", error.response.data.error_message);
      }
    };
    AddWishList();
  };

  // const handleSearch = () => {
  //   // const searchResponse = [...products].filter((item:any) => {
  //   //   return item
  //   // })
  //   console.log(searchText, "searchText");
  //   if (searchText?.trim() !== "") {
  //     const filteredData = [...initialStores].filter((item: any) =>
  //       item?.product?.product_name
  //         .toLowerCase()
  //         .includes(searchText.toLowerCase())
  //     );
  //     console.log(filteredData, "filteredData=========>");
  //     setStores(filteredData);
  //   } else {
  //     setStores(initialStores);
  //     // console.log(filteredData,"filteredData=========>")
  //   }
  // };

  const handleSort = (item: any) => {
    setSortBy(item);
    if (item?.name) {
      const sortedProducts: any = [...stores].sort((a: any, b: any) => {
        const itemA =
          item.Sorttype === "PRDPrice"
            ? parseFloat(a?.product?.PRDPrice)
            : (a?.product?.product_name).toLowerCase();
        const itemB =
          item.Sorttype === "PRDPrice"
            ? parseFloat(b?.product?.PRDPrice)
            : (b?.product?.product_name).toLowerCase();

        if (item?.Ascending) {
          if (itemA < itemB) return -1;
          if (itemA > itemB) return 1;
        } else {
          if (itemA > itemB) return -1;
          if (itemA < itemB) return 1;
        }
        return 0;
      });

      setStores(sortedProducts);
    } else {
      setStores(() => [...stores]);
    }
  };

  const handleOverlayClose = () => {
    setShowSortPopover(false);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch("");
    }
  };
  const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format

  // console.log(userLatitude)
  // console.log(userLongitude)
  return (
    <>
      <Section className="storeView" style={styles.bg}>
        <Container>
          <Row>
            <Col>
              <div className="breadcrumbs">
                {breadcrumbs.map((crumb, index) => (
                  <React.Fragment key={index}>
                    <span
                      style={{
                        color: index === 0 ? "var(--primary-color)" : "#BEB8B8",
                        fontFamily: "var(--primary-font-medium)",
                        fontSize: 16,
                      }}
                    >
                      {crumb}
                    </span>
                    {index !== breadcrumbs.length - 1 && (
                      <FaArrowRight
                        className="mx-2"
                        style={{ color: "#BEB8B8" }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </Col>
          </Row>

          <Row style={styles.searchRow}>
            <ReactSearchAutocomplete
              className="mr-sm-2 search-input"
              items={suggestions}
              onSearch={(input: string) => setSearchText(input)}
              onSelect={(item: any) => handleSearch(item?.name)}
              formatResult={(item: any) => (
                <div>
                  {item?.img && (
                    <span>
                      <img
                        src={`${base_url}${item?.img}`}
                        style={{ width: "2rem" }}
                      />
                    </span>
                  )}
                  <span>{item?.name}</span>
                </div>
              )}
              autoFocus
            />
            <Button
              variant="outline-primary search-btn"
              onClick={() => handleSearch("")}
            >
              <FaSearch />
            </Button>
          </Row>
        </Container>
      </Section>
      {/* <TitleBar breadcrumbs={breadcrumbs} title={title} imageUrl={imageUrl} /> */}

      <Section
        className="nearByProducts"
        style={{ paddingTop: 50, paddingBottom: 50 }}
      >
        <Container>
          {!loading && stores.length !== 0 && (
            <div
              className="d-flex align-items-center justify-content-end flex-row mb-3"
              style={{ position: "relative" }}
            >
              <div ref={target}>
                <Button
                  title="Sort"
                  style={styles.btnSort}
                  variant="outline-primary"
                  onClick={() => setShowSortPopover(!showSortPopover)}
                >
                  <Image src={Sort} className="sortImage" />
                </Button>
                <Overlay
                  show={showSortPopover}
                  target={target.current}
                  placement="bottom"
                  containerPadding={20}
                  container={document.body}
                  onHide={handleOverlayClose}
                  rootClose
                >
                  <Popover id="popover-contained">
                    {/* <Popover.Header as="h3">Sort By</Popover.Header> */}
                    <Popover.Body>
                      <ul
                        style={{ listStyleType: "none", padding: 0 }}
                        className="sortPopover"
                      >
                        {sortData &&
                          sortData.map((ele: any) => {
                            return (
                              <li>
                                <Button
                                  variant="link"
                                  onClick={() => handleSort(ele)}
                                >
                                  {ele?.name}
                                </Button>
                              </li>
                            );
                          })}
                      </ul>
                    </Popover.Body>
                  </Popover>
                </Overlay>
              </div>
            </div>
          )}
          <Row>
            {loading ? (
              <div className="d-flex justify-content-center align-items-center h-100">
                <Spinner animation="grow" variant="primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : stores.length === 0 ? (
              <div>
                <NoDataFound title={t("offerList.noOffersFound")} />
              </div>
            ) : (
              stores &&
              stores.map((item: any, index: any) => (
                <Col className="col-sm-3" key={item.product?.id}>
                  <div style={styles.product} className="product-card">
                    <div
                      style={styles.imageWrap}
                      className="product-image-wrap"
                    >
                      {item.product?.current_price &&
                        item.product?.current_price?.discount_type && (
                          <div style={styles.offerBadgeWrap}>
                            <div style={styles.arrowRight}></div>
                            <p style={styles.text} className="mb-0">
                              {item?.product?.current_price?.discount_type ===
                              "amount"
                                ? `${item?.product?.current_price?.discount_val} Rs`
                                : `${item?.product?.current_price?.discount_val} %`}
                              <br /> OFF
                            </p>
                          </div>
                        )}
                      {item.product?.current_price &&
                        item.product?.current_price.discount_type && (
                          <>
                            {item.product?.current_price.offer_valid_to ===
                              today && (
                              <Badge bg="danger">
                                Ends <br></br>Today
                              </Badge>
                            )}
                          </>
                        )}
                      <Image
                        className="product-image"
                        src={`${base_url}/${item.product?.product_image}`}
                        style={styles.productImage}
                      />
                      <div style={styles.heartImageWrap}>
                        <FontAwesomeIcon
                          icon={
                            wishlist.includes(item.product?.id)
                              ? faHeart as any
                              : faHeartOutline as any
                          }
                          onClick={() => {
                            wishlist.includes(item.product?.id)
                              ? handleRemoveFromWishlist(item.product?.id)
                              : AddToWishList(item.product?.id);
                          }}
                        />
                      </div>
                    </div>

                    <div style={styles.productContent}>
                      <div className="d-flex align-items-center  shopInfo">
                        <div
                          className="shopImageWrap"
                          style={styles.shopIconWrap}
                        >
                          <Image
                            src={
                              item.product?.product_vendor_store?.logo
                                ? `${base_url}/${item.product?.product_vendor_store?.logo}`
                                : ShopImage
                            }
                            style={styles.shopIcon}
                          />
                        </div>
                        <h2 style={styles.shopName} className="mb-0 shopName">
                          {item.product?.product_vendor_store?.language_data
                            ?.length === 0
                            ? item.product?.product_vendor_store?.store_name
                            : languageId === "ne"
                            ? item.product?.product_vendor_store
                                ?.language_data[0]?.store_name
                            : item.product?.product_vendor_store?.store_name ||
                              "Unknown Store"}
                        </h2>
                      </div>

                      <h2
                        className="product-name"
                        style={styles.productName}
                        onClick={() => handleClick(item.product?.id)}
                      >
                        {manageTextLength(
                          item?.product?.language_data?.length === 0
                            ? item?.product?.product_name
                            : languageId === "ne"
                            ? item?.product?.language_data[0]?.product_name
                            : item?.product?.product_name || ""
                        )}
                      </h2>
                      <div className="d-flex align-items-center justify-content-between wt-brand">
                        <h3 style={styles.quantity}>
                          {item.product?.PRDWeight} {item.product?.unit_measure}
                        </h3>
                        <div>
                          {item.product?.brand && (
                            <h3 className="brandName">
                              {manageBrandTextLength(item.product.brand)}
                            </h3>
                          )}
                        </div>
                      </div>

                      <div style={styles.priceWrap}>
                        {item?.product?.current_price ? (
                          <span
                            style={styles.offerPrice}
                            className="offerPrice"
                          >
                            रु
                            {parseFloat(
                              item.product?.current_price?.current_price
                            ).toFixed(2)}
                          </span>
                        ) : (
                          <span style={styles.offerPrice} className="price">
                            रु
                            {parseFloat(
                              (item.product?.PRDPrice || 0).toString()
                            ).toFixed(2)}
                          </span>
                        )}
                        {item?.product?.current_price && (
                          <span style={styles.price} className="price">
                            रु
                            {
                              parseFloat(
                                item?.product?.PRDPrice.toFixed(2)
                              ) as unknown as string
                            }
                          </span>
                        )}
                      </div>

                      <div className="d-grid gap-2">
                        {outOfstock.length > 0 &&
                        outOfstock.includes(item?.product?.id) ? (
                          <Button
                            style={styles.btnAdd}
                            size="lg"
                            disabled
                            className="btn-outOfStock"
                          >
                            {t("commonHeader.outOfStock")}
                          </Button>
                        ) : (
                          <Button
                            style={styles.btnAdd}
                            size="lg"
                            onClick={() =>
                              handleCart(
                                item?.product?.id,
                                item?.store?.id,
                                item?.store?.store_name,
                                item?.product?.product_subcategory,
                                item?.product?.minimum_qty,
                                item?.product?.product_vendor_store
                                  ?.store_rating
                              )
                            }
                          >
                            {" "}
                            {t("commonHeader.add")}
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              ))
            )}
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default NearByProducts;
