import React, { useCallback, useEffect, useState, useRef } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  Button,
  Image,
  Spinner,
  FormControl,
  Overlay,
} from "react-bootstrap";
import styles from "./ProductReviews.style";
import TitleBar from "../../Components/TitleBar/TitleBar";
import NearByProductsImage from "../../assets/images/nearByProducts.png";
import Section from "../../Components/Section/Section";
import shop1 from "../../assets/images/shop2.png";
import locationIcon from "../../assets/images/location.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bookmark from "../../assets/images/supply.png";
import Dot from "../../assets/images/dot.png";
import { FaArrowRight, FaSearch, FaStar } from "react-icons/fa";
import { faHeart as faHeartOutline } from "@fortawesome/free-regular-svg-icons";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/Store";
import ShopImage from "../../assets/images/default-shop.png";
import {
  Add_To_Cart,
  Add_To_Wishlist,
  Nearest_Shops,
  Product_Ratings,
  Products_for_Review,
  Top_Deals,
  User_Product_Rating,
  View_Cart,
} from "../../Api";
import { Link, useNavigate } from "react-router-dom";
import noProduct from "../../assets/images/no-product.png";
import Sort from "../../assets/images/sort.png";
import ProductImage from "../../assets/images/aata.png";
import Heart from "../../assets/images/Heart.png";
import _ from "lodash";
import { base_url } from "../../Api/ApiConst";
import { SET_SELECTED_PRODUCT } from "../../Redux/productId";
import { toast } from "react-toastify";
import { VIEW_CART } from "../../Redux/Cart";
import {
  AddToWishList,
  handleRemoveFromWishlist,
} from "../../Utils/wishListUtils";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { getOutOfStockProduct } from "../../Utils/OutOfStock";
import { useTranslation } from "react-i18next";
import { SET_SELECTED_STORE_SUBCATEGORY } from "../../Redux/User";
import InputGroup from "react-bootstrap/esm/InputGroup";
import Popover from "react-bootstrap/esm/Popover";
import Modal from "react-bootstrap/esm/Modal";
import { Rating } from "react-simple-star-rating";

interface Category {
  product?: {
    id: string;
    product_name: string;
    product_image: string;
    PRDPrice: number;
    PRDWeight: string;
    current_price?: {
      discount_type: string;
      discount_val: string;
      current_price: string;
    };
  };

  store?: {
    id: string;
    store_name: string;
  };
}

const ProductReviews: React.FC = () => {
  const imageUrl: string = NearByProductsImage; // Corrected imageUrl

  const [latitude, setLatitude] = React.useState<string | null>("");
  const [longitude, setLongitude] = React.useState<string | null>("");
  const selectedCatgeory: any = useSelector(
    (state: RootState) => state.mainCategory.selectedMainCategory
  );
  const [cateName, setCateName] = useState<string | null>("");
  const [stores, setStores] = React.useState<any>([]);
  const [userToken, setUserToken] = React.useState<string | null>("");
  const languageId: any = useSelector((state: RootState) => state.lan);
  const [loading, setLoading] = useState<boolean>(false);
  const [initialStores, setInitialStores] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [isSearchResult, setIsSearchResult] = useState<boolean>(false);
  const [pages, setPages] = useState<any>(""); // Track if the current view is search results
  const wishlist: any = useSelector(
    (state: RootState) => state.User.productIdInWishList
  );
  const userLatitude = useSelector(
    (state: RootState) => state.Location.selected_latitude
  );
  const userLongitude = useSelector(
    (state: RootState) => state.Location.selected_longitude
  );
  const mainName = useSelector(
    (state: RootState) => state.mainCategory.selectedMainCategory
  );
  const outOfstock = useSelector((state: RootState) => state.offer.OutOfStock);
  const [searchText, setSearchText] = useState<string>("");
  const [sortBy, setSortBy] = useState<string>("");
  const { t, i18n } = useTranslation();
  const [showSortPopover, setShowSortPopover] = useState(false);
  const target = useRef(null);
  const [showRatingModal, setShowRatingModal] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [rating, setRating] = useState<number>(0);
  const [comment, setComment] = useState<string>("");
  const [item, setItem] = useState<any>("");
  const [limit, setLimit] = useState(8);

  const sortData = [
    {
      id: 1,
      Sorttype: "product_name",
      name: t("offerSortModal.productNameAsc"),
      Ascending: true,
    },
    {
      id: 2,
      Sorttype: "product_name",
      name: t("offerSortModal.productNameDesc"),
      Ascending: false,
    },
    // {
    //   id: 3,
    //   Sorttype: "PRDPrice",
    //   name: t("storeSortModal.priceAsc"),
    //   Ascending: true,
    // },
    // {
    //   id: 4,
    //   Sorttype: "PRDPrice",
    //   name: t("storeSortModal.priceDesc"),
    //   Ascending: false,
    // },
  ];

  const title: string = t("nearByProducts.nearByProducts");

  const [currency, setCurrency] = useState("NPR");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const breadcrumbs = [
    <Link to="/" key="home">
      {t("footer.home")}
    </Link>,
    "Product's Review",
  ];

  useEffect(() => {
    setLatitude(localStorage.getItem(STORAGE_CONSTANTS.LATITUDE));
    setLongitude(localStorage.getItem(STORAGE_CONSTANTS.LONGITUDE));
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    getOutOfStockProduct();
  }, []);

  const fetchOrders = async (currentPage: any) => {
    try {
      setLoading(true);
      const response = await Products_for_Review(currentPage, limit);
      if (response && response.status === 200) {
        // console.log(response.data.store_list.language_data[0].store_name);
        setPages(response.data.count);
        console.log(response.data, "buyyyy---------->");
        setStores(response.data.product_list);
        setInitialStores(response.data.product_list)
      } else {
        console.log("Error fetching Past orders");
      }
    } catch (error) {
      console.log("Error fetching Past orders", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders(currentPage);
  }, [currentPage]);

  const manageTextLength = (text: string): string => {
    if (text.length > 50) {
      let newText = text.slice(0, 45) + "...";
      return newText;
    } else {
      return text;
    }
  };
  const handleClick = (id: any) => {
    dispatch(SET_SELECTED_PRODUCT(id));
    navigate("/product-details");
  };

  // const handleCart = (
  //   id: any,
  //   storeId: any,
  //   store_name: any,
  //   subcategory: any
  // ) => {
  //   const addToCart = async () => {
  //     try {
  //       const response = await Add_To_Cart(id, currency);
  //       if (response && response.status === 200) {
  //         // console.log(response.data, "jhhhhhhhhhhhhhhh")
  //         localStorage.setItem(STORAGE_CONSTANTS.SHOP_ID, storeId);
  //         localStorage.setItem(
  //           STORAGE_CONSTANTS.ORDER_ID,
  //           response.data?.order_id
  //         );
  //         dispatch(SET_SELECTED_STORE_SUBCATEGORY(subcategory));
  //         const state = {
  //           store_name: store_name,
  //         };
  //         const Cartresponse = await View_Cart();
  //         if (Cartresponse && Cartresponse.status === 200) {
  //           // console.log(Cartresponse.data.cart_details.cart_items, "kkkkkkkkkkkk");
  //           dispatch(VIEW_CART(Cartresponse.data));
  //         }
  //         navigate("/store", { state: state });

  //         toast.success(response["data"]["message"]);
  //       } else {
  //         toast.error(response["data"]["message"]);
  //       }
  //     } catch (error: any) {
  //       console.log("Error occurred:", error.response.data.error_message);
  //     }
  //   };
  //   addToCart();
  // };

  // const handleWishlist = (id: any) => {
  //   const AddWishList = async () => {
  //     try {
  //       const response = await Add_To_Wishlist(userToken, id);
  //       if (response && response.status === 200) {
  //         console.log(response.data, "uuuuuuuuuuu");
  //         toast.success(response["data"]["message"]);
  //       } else {
  //         toast.error(response["data"]["message"]);
  //       }
  //     } catch (error: any) {
  //       console.log("Error occurred:", error.response.data.error_message);
  //     }
  //   };
  //   AddWishList();
  // };

  // const handleSearch = () => {
  //   // const searchResponse = [...products].filter((item:any) => {
  //   //   return item
  //   // })
  //   console.log(searchText, "searchText");
  //   if (searchText?.trim() !== "") {
  //     const filteredData = [...initialStores].filter((item: any) =>
  //       item?.product?.product_name
  //         .toLowerCase()
  //         .includes(searchText.toLowerCase())
  //     );
  //     console.log(filteredData, "filteredData=========>");
  //     setStores(filteredData);
  //   } else {
  //     setStores(initialStores);
  //     // console.log(filteredData,"filteredData=========>")
  //   }
  // };

  // const handleSort = (item: any) => {
  //   setSortBy(item);
  //   if (item?.name) {
  //     const sortedProducts: any = [...stores].sort((a: any, b: any) => {
  //       const itemA =
  //         item.Sorttype === "PRDPrice"
  //           ? parseFloat(a?.product?.PRDPrice)
  //           : (a?.product?.product_name).toLowerCase();
  //       const itemB =
  //         item.Sorttype === "PRDPrice"
  //           ? parseFloat(b?.product?.PRDPrice)
  //           : (b?.product?.product_name).toLowerCase();

  //       if (item?.Ascending) {
  //         if (itemA < itemB) return -1;
  //         if (itemA > itemB) return 1;
  //       } else {
  //         if (itemA > itemB) return -1;
  //         if (itemA < itemB) return 1;
  //       }
  //       return 0;
  //     });

  //     setStores(sortedProducts);
  //   } else {
  //     setStores(() => [...stores]);
  //   }
  // };

  const handleOverlayClose = () => {
    setShowSortPopover(false);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleOpenRatingModal = (product: any) => {
    // setSelectedProduct(product);
    setShowRatingModal(true);
    setItem(product);

    if (product?.id) {
      const OrderRating = async () => {
        try {
          const response = await Product_Ratings(product?.id);
          if (response && response.status === 200) {
            // console.log(response.data.store_list.language_data[0].store_name);
            console.log(response.data, "buyyyy---------->");
            // setProducts(response.data.product_list);
            setSelectedProduct(response?.data?.product_rating_list);
            setComment(response?.data?.product_rating_list[0]?.client_comment);
          } else {
            console.log("Error fetching Past orders");
          }
        } catch (error) {
          console.log("Error fetching Past orders", error);
        }
      };
      OrderRating();
    }
  };

  const handleRate = (rate: number) => {
    console.log("rate", rate);
    setRating(rate);
  };

  const handleSubmit = () => {
     if(rating){
      const RateProduct = async () => {
        try {
          const payload = {
            rate: rating,
            client_comment: comment,
          };
          const response = await User_Product_Rating(payload, item?.id);
          if (response && response.status === 200) {
            // console.log(response.data.store_list.language_data[0].store_name);
            console.log(response.data, "buyyyy---------->");
            // setProducts(response.data.product_list);
            setSelectedProduct(response?.data?.product_rating_list);
            toast.success(response["data"]["message"]);
            fetchOrders(currentPage);
            handleCloseModal();
          } else {
            console.log("Error fetching Past orders");
          }
        } catch (error: any) {
          console.log("Error fetching Past orders", error);
          toast.error(error?.response?.data?.message);
        }
      };
      RateProduct();
     }else{
      toast.error("Please rate the product")
     }
  };

  const handleCloseModal = () => {
    setShowRatingModal(false);
    setComment("");
  };

  const handleSort = (item: any) => {
    setSortBy(item);
    if (item?.name) {
      const sortedProducts: any = [...stores].sort((a: any, b: any) => {
        const itemA =
          item.Sorttype === "PRDPrice"
            ? parseFloat(a?.PRDPrice)
            : (a?.product_name).toLowerCase();
        const itemB =
          item.Sorttype === "PRDPrice"
            ? parseFloat(b?.PRDPrice)
            : (b?.product_name).toLowerCase();

        if (item?.Ascending) {
          if (itemA < itemB) return -1;
          if (itemA > itemB) return 1;
        } else {
          if (itemA > itemB) return -1;
          if (itemA < itemB) return 1;
        }
        return 0;
      });

      setStores(sortedProducts);
    } else {
      setStores(() => [...stores]);
    }
  };


  const handleSearch = () => {
    // const searchResponse = [...products].filter((item:any) => {
    //   return item
    // })
    console.log(searchText, "searchText");
    if (searchText?.trim() !== "") {
      const filteredData = [...initialStores].filter((item: any) =>
        item?.product_name
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );
      console.log(filteredData, "filteredData=========>");
      setStores(filteredData);
    } else {
      setStores(initialStores);
      // console.log(filteredData,"filteredData=========>")
    }
  };

  // console.log(userLatitude)
  // console.log(userLongitude)
  return (
    <>
      <Section className="storeView" style={styles.bg}>
        <Container>
          <Row>
            <Col>
              <div className="breadcrumbs">
                {breadcrumbs.map((crumb, index) => (
                  <React.Fragment key={index}>
                    <span
                      style={{
                        color: index === 0 ? "var(--primary-color)" : "#BEB8B8",
                        fontFamily: "var(--primary-font-medium)",
                        fontSize: 16,
                      }}
                    >
                      {crumb}
                    </span>
                    {index !== breadcrumbs.length - 1 && (
                      <FaArrowRight
                        className="mx-2"
                        style={{ color: "#BEB8B8" }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </Col>
          </Row>

          {/* <Row style={styles.searchRow}>
            <InputGroup>
              <FormControl
                type="text"
                placeholder={t("commonHeader.globalSearch")}
                className="mr-sm-2 search-input"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <Button
                variant="outline-primary search-btn"
                onClick={handleSearch}
              >
                <FaSearch />
              </Button>
            </InputGroup>
          </Row> */}
        </Container>
      </Section>
      {/* <TitleBar breadcrumbs={breadcrumbs} title={title} imageUrl={imageUrl} /> */}

      <Section
        className="nearByProducts"
        style={{ paddingTop: 40, paddingBottom: 100 }}
      >
        <Container>
          {/* {!loading && stores.length !== 0 && (
            <div className="d-flex align-items-center justify-content-end flex-row mb-3">
              <div ref={target}>
                <Button
                  title="Sort"
                  style={styles.btnSort}
                  variant="outline-primary"
                  onClick={() => setShowSortPopover(!showSortPopover)}
                >
                  <Image src={Sort} className="sortImage" />
                </Button>
                <Overlay
                  show={showSortPopover}
                  target={target.current}
                  placement="bottom"
                  containerPadding={20}
                  container={document.body}
                  onHide={handleOverlayClose}
                  rootClose
                >
                  <Popover id="popover-contained">
                    <Popover.Header as="h3">Sort By</Popover.Header>
                    <Popover.Body>
                      <ul
                        style={{ listStyleType: "none", padding: 0 }}
                        className="sortPopover"
                      >
                        {sortData &&
                          sortData.map((ele: any) => {
                            return (
                              <li>
                                <Button
                                  variant="link"
                                  onClick={() => handleSort(ele)}
                                >
                                  {ele?.name}
                                </Button>
                              </li>
                            );
                          })}
                      </ul>
                    </Popover.Body>
                  </Popover>
                </Overlay>
              </div>
            </div>
          )} */}
          <Row className="prod-reviews"> 
            {loading ? (
              <div className="d-flex justify-content-center align-items-center h-100">
                <Spinner animation="grow" variant="primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : stores.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <h3 style={styles.noProduct}>
                  <Image
                    src={noProduct}
                    className="mx-2"
                    style={{ width: 32, height: 32 }}
                  />
                  {t("offerList.noOffersFound")}
                </h3>
              </div>
            ) : (
              stores &&
              stores.map((item: any, index: any) => (
                <Col className="col-sm-3" key={item?.id}>
                  <div
                    style={styles.product}
                    className="product-card"
                    onClick={() => handleOpenRatingModal(item)}
                  >
                    <div
                      style={styles.imageWrap}
                      className="product-image-wrap"
                    >
                      <Image
                        className="product-image"
                        src={`${base_url}/${item?.product_image}`}
                        style={styles.productImage}
                        onClick={() => handleOpenRatingModal(item)}
                      />
                      {/* <div style={styles.heartImageWrap}>
                        <FontAwesomeIcon
                          icon={
                            wishlist.includes(item.id)
                              ? faHeart
                              : faHeartOutline
                          }
                          onClick={() => {
                            wishlist.includes(item.id)
                              ? handleRemoveFromWishlist(item.id)
                              : AddToWishList(item.id);
                          }}
                        />
                      </div> */}
                    </div>

                    <div style={styles.productContent}>
                      <div className="d-flex align-items-center mb-2">
                        <Image
                          src={
                            item?.product_vendor_store?.logo
                              ? `${base_url}/${item?.product_vendor_store?.logo}`
                              : ShopImage
                          }
                          style={styles.shopIcon}
                        />
                        <h2 style={styles.shopName} className="mb-0 mx-2">
                          {item?.product_vendor_store?.language_data?.length ===
                          0
                            ? item?.product_vendor_store?.store_name
                            : languageId === "ne"
                            ? item?.product_vendor_store?.language_data[0]
                                ?.store_name
                            : item?.product_vendor_store?.store_name ||
                              "Unknown Store"}
                        </h2>
                      </div>
                      <h2
                        className="product-name"
                        style={styles.productName}
                        onClick={() => handleOpenRatingModal(item)}
                      >
                        {manageTextLength(
                          item?.language_data?.length > 0
                            ? languageId === "ne"
                              ? item?.language_data[0]?.product_name
                              : item?.product_name
                            : item?.product_name || ""
                        )}
                      </h2>
                      {/* <h3 style={styles.quantity}>{item?.PRDWeight} kg</h3> */}
                      {/* <div style={styles.priceWrap}>
                        {item?.current_price ? (
                          <span
                            style={styles.offerPrice}
                            className="offerPrice"
                          >
                            रु
                            {parseFloat(
                              item?.current_price?.current_price
                            ).toFixed(2)}
                          </span>
                        ) : (
                          <span style={styles.offerPrice} className="price">
                            रु
                            {parseFloat(
                              (item?.PRDPrice || 0).toString()
                            ).toFixed(2)}
                          </span>
                        )}
                        {item?.current_price && (
                          <span style={styles.price} className="price">
                            रु
                            {
                              parseFloat(
                                item?.PRDPrice.toFixed(2)
                              ) as unknown as string
                            }
                          </span>
                        )}
                      </div> */}

                      {/* <div className="d-grid gap-2">
                        {outOfstock.length > 0 &&
                        outOfstock.includes(item?.product?.id) ? (
                          <Button
                            style={styles.btnAdd}
                            size="lg"
                            disabled
                            className="btn-outOfStock"
                          >
                            {t("commonHeader.outOfStock")}
                          </Button>
                        ) : (
                          <Button
                            style={styles.btnAdd}
                            size="lg"
                            onClick={() =>
                              handleCart(
                                item?.product?.id,
                                item.store?.id,
                                item.store?.store_name,
                                item?.product?.product_subcategory
                              )
                            }
                          >
                            {" "}
                            {t("commonHeader.add")}
                          </Button>
                        )}
                      </div> */}
                      <div>
                        <Rating
                          initialValue={item?.product_rating}
                          allowHover={false}
                          readonly={true}
                          size={22}
                          style={{ marginBottom: "7rem" }}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              ))
            )}

            {
              <Modal
                show={showRatingModal}
                onHide={handleCloseModal}
                className="ratingModal"
              >
                <Modal.Header closeButton>
                  <Modal.Title>{t("userProfile.shareReview")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="d-flex align-items-center mb-3">
                    <Image
                      src={
                        item?.product_image
                          ? `${base_url}/${item?.product_image}`
                          : ShopImage
                      }
                      style={{
                        width: 50,
                        height: 50,
                        marginRight: 10,
                        borderRadius: 10,
                      }}
                    />
                    <h5 className="mb-0">
                      {item?.language_data?.length > 0
                        ? languageId === "ne"
                          ? item?.language_data[0]?.product_name
                          : item?.product_name
                        : item?.product_name || "Unknown Product"}
                    </h5>
                  </div>
                  <div className="mb-3">
                    <Rating
                      initialValue={selectedProduct && selectedProduct[0]?.rate}
                      allowHover={false}
                      readonly={
                        selectedProduct && selectedProduct[0]?.rate
                          ? true
                          : false
                      }
                      size={33}
                      style={{ marginBottom: "7rem" }}
                      onClick={handleRate}
                    />
                  </div>
                  <Form.Group controlId="formComment">


                    
                {selectedProduct && selectedProduct[0]?.rate ? (
                  comment ? (
                    <p className="mb-2" style={{ background: "#fff", padding: 10, border: "1px solid #dee2e6", borderRadius: 10 }}>{comment}</p>
                  ): null
                ) : (
                  <>  <Form.Label>{t("userProfile.comment")}</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                  </>
                )}


                   
                  </Form.Group>
                  {selectedProduct && selectedProduct[0]?.rate ? (
                    <h6  style={{ padding: 5 }}>{t("userProfile.ratingThankYouMessage")}</h6>
                  ) : (
                    ""
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    className="btnCancel"
                    onClick={handleCloseModal}
                  >
                    {t("deleteModal.cancel")}
                  </Button>
                  {selectedProduct && selectedProduct[0]?.rate ? (
                    ""
                  ) : (
                    <Button
                      variant="primary"
                      className="btnBlue"
                      onClick={() => handleSubmit()}
                      disabled={
                        selectedProduct && selectedProduct[0]?.rate
                          ? true
                          : false
                      }
                    >
                      {t("commonFormLabels.submit")}
                    </Button>
                  )}
                </Modal.Footer>
              </Modal>
            }

            {/* <Col className='col-sm-3'>
              <div style={styles.product}>
                <div style={styles.imageWrap}>
                  <div style={styles.offerBadgeWrap}>
                    <div style={styles.arrowRight}>
                      20% OFF
                    </div>
                  </div>
                  <Image src={ProductImage} style={styles.productImage} />
                  <div style={styles.heartImageWrap}>
                    <Image src={Heart} style={styles.heartImage} />
                  </div>
                </div>

                <div style={styles.productContent}>

                  <h2 style={styles.productName}>Product Name</h2>
                  <h3 style={styles.quantity}>2 kg</h3>
                  <div style={styles.priceWrap}>

                    <span style={styles.offerPrice}>रु200.00</span>
                    <span style={styles.price}>रु270.00</span>
                  </div>
                  <div className="d-grid gap-2">  <Button style={styles.btnAdd} size="lg">Add</Button></div>
                </div>
              </div>
            </Col> */}

            {/* <Col className='col-sm-3'>
              <div style={styles.product}>
                <div style={styles.imageWrap}>
                  <div style={styles.offerBadgeWrap}>
                    <div style={styles.arrowRight}>
                      20% OFF
                    </div>
                  </div>
                  <Image src={ProductImage} style={styles.productImage} />
                  <div style={styles.heartImageWrap}>
                    <Image src={Heart} style={styles.heartImage} />
                  </div>
                </div>

                <div style={styles.productContent}>

                  <h2 style={styles.productName}>Product Name</h2>
                  <h3 style={styles.quantity}>2 kg</h3>
                  <div style={styles.priceWrap}>

                    <span style={styles.offerPrice}>रु200.00</span>
                    <span style={styles.price}>रु270.00</span>
                  </div>
                  <div className="d-grid gap-2">  <Button style={styles.btnAdd} size="lg">Add</Button></div>
                </div>
              </div>
            </Col>



            <Col className='col-sm-3'>
              <div style={styles.product}>
                <div style={styles.imageWrap}>
                  <div style={styles.offerBadgeWrap}>
                    <div style={styles.arrowRight}>
                      20% OFF
                    </div>
                  </div>
                  <Image src={ProductImage} style={styles.productImage} />
                  <div style={styles.heartImageWrap}>
                    <Image src={Heart} style={styles.heartImage} />
                  </div>
                </div>

                <div style={styles.productContent}>

                  <h2 style={styles.productName}>Product Name</h2>
                  <h3 style={styles.quantity}>2 kg</h3>
                  <div style={styles.priceWrap}>

                    <span style={styles.offerPrice}>रु200.00</span>
                    <span style={styles.price}>रु270.00</span>
                  </div>
                  <div className="d-grid gap-2">  <Button style={styles.btnAdd} size="lg">Add</Button></div>
                </div>
              </div>
            </Col>


            <Col className='col-sm-3'>
              <div style={styles.product}>
                <div style={styles.imageWrap}>
                  <div style={styles.offerBadgeWrap}>
                  <div style={styles.arrowRight}>
                  20% OFF
                  </div>
                </div>
                  <Image src={ProductImage} style={styles.productImage} />
                  <div style={styles.heartImageWrap}>
                    <Image src={Heart} style={styles.heartImage} />
                  </div>
                </div>

                <div style={styles.productContent}>

                  <h2 style={styles.productName}>Product Name</h2>
                  <h3 style={styles.quantity}>2 kg</h3>
                  <div style={styles.priceWrap}>

                    <span style={styles.offerPrice}>रु200.00</span>
                    <span style={styles.price}>रु270.00</span>
                  </div>
                  <div className="d-grid gap-2">  <Button style={styles.btnAdd} size="lg">Add</Button></div>
                </div>
              </div>
            </Col> */}
          </Row>
          {!loading && stores.length !== 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                float: "right",
              }}
            >
              {(isSearchResult ? pages <= 10 && stores.length >= 10 : true) && (
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    <li
                      className={`page-item ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        onClick={() =>
                          setCurrentPage(Math.max(currentPage - 1, 1))
                        }
                      >
                        {t("userProfile.previous")}
                      </a>
                    </li>
                    {Array.from({ length: pages }).map((_, index) => (
                      <li
                        className={`page-item ${
                          currentPage === index + 1 ? "active" : ""
                        }`}
                        key={index}
                      >
                        <a
                          className="page-link"
                          onClick={() => setCurrentPage(index + 1)}
                        >
                          {index + 1}
                        </a>
                      </li>
                    ))}
                    <li
                      className={`page-item ${
                        currentPage === pages ? "disabled" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        onClick={() =>
                          setCurrentPage(Math.min(currentPage + 1, pages))
                        }
                      >
                        {t("userProfile.next")}
                      </a>
                    </li>
                  </ul>
                </nav>
              )}
            </div>
          )}
        </Container>
      </Section>
    </>
  );
};

export default ProductReviews;
